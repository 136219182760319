import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import tenantReducer from './tenantReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import contractReducer from './contractReducer';
import systemReducer from './systemReducer';
import ticketReducer from './ticketReducer';
import kanbanReducer from './kanbanReducer';
import offerReducer from './offerReducer';
import errorReducer from './errorReducer';
import cmsReducer from './cmsReducer';
import fileReducer from './fileReducer';
import invoiceReducer from './invoiceReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  tenant: tenantReducer,
  contract: contractReducer,
  system: systemReducer,
  ticket: ticketReducer,
  offer: offerReducer,
  error: errorReducer,
  cms: cmsReducer,
  file: fileReducer,
  invoice: invoiceReducer,
  report: reportReducer,
  form: formReducer
});

export default rootReducer;
