import { gql } from 'apollo-boost';
import client from 'src/utils/apolloClient';

export const GET_TENANT = '@tenant/get-tenant';

const TENANT = gql`
  query Tenant($id: Int!) {
    tenant(id: $id) {
      id
      active
      businessRegisterNumber
      code
      iban
      name
      phone
      responsiblePerson
      address {
        id
        city
        country
        state
        street
        zip
      }
    }
  }
`;

export function getTenant(id) {
  const request = client.query({
    query: TENANT,
    variables: { id },
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_TENANT,
        payload: response.data
      })
    );
  };
}
