/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_OPEN_INVOICE,
  GET_INVOICE,
  GET_INVOICES,
  GET_OPEN_INVOICES,
  GET_OPEN_OFFER_INVOICES,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  GET_OPEN_OFFER_INVOICE
} from 'src/actions/invoiceActions';
import { InvoiceType } from 'src/generated/graphql';
import { InvoiceState } from 'src/store/storeInterface';

const initialState: InvoiceState = {
  invoices: [],
  openInvoices: [],
  openOfferInvoices: [],
  invoice: {} as InvoiceType,
  openInvoice: {} as InvoiceType,
  openOfferInvoice: {} as InvoiceType
};

const cmsReducer = (state = initialState, action: { type: string; payload: any }) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_OPEN_OFFER_INVOICES: {
      const { openOfferInvoices } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.openOfferInvoices = openOfferInvoices;
      });
    }
    case GET_OPEN_INVOICES: {
      const { openInvoices } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.openInvoices = openInvoices;
      });
    }
    case GET_INVOICES: {
      const { invoices } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.invoices = invoices;
      });
    }
    case GET_OPEN_INVOICE: {
      const { openInvoice }: { openInvoice: InvoiceType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.openInvoice = openInvoice;
      });
    }
    case GET_OPEN_OFFER_INVOICE: {
      const { openOfferInvoice }: { openOfferInvoice: InvoiceType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.openOfferInvoice = openOfferInvoice;
      });
    }
    case GET_INVOICE: {
      const { invoice }: { invoice: InvoiceType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.invoices = draft.invoices.map(i => (i.id === invoice.id ? invoice : i));
        draft.invoice = invoice;
      });
    }
    case CREATE_INVOICE: {
      const { createInvoice }: { createInvoice: InvoiceType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.invoices = [...draft.invoices, createInvoice];
        draft.openInvoices = draft.openInvoices.filter(i => i.id !== createInvoice.maintenanceContractID);
      });
    }
    case UPDATE_INVOICE: {
      const { updateInvoice }: { updateInvoice: InvoiceType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.invoice = updateInvoice;
        draft.invoices = draft.invoices.map(i =>
          i.id === updateInvoice.id ? { ...i, status: updateInvoice.status } : i
        );
      });
    }
    default: {
      return state;
    }
  }
};

export default cmsReducer;
