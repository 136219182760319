import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'src/store/storeInterface';
import client from 'src/utils/apolloClient';
import {
  TranslationsDocument,
  SaveTranslationDocument,
  SaveTranslationMutationVariables,
  SaveTranslationMutation,
  SaveTemplateMutationVariables,
  SaveTemplateDocument,
  SaveTemplateMutation,
  TemplateQueryVariables,
  TemplateDocument,
  TemplatesDocument,
  ConfigurationsDocument,
  SaveConfigurationMutationVariables,
  SaveConfigurationDocument
} from 'src/generated/graphql';
import { handleResponseError } from './errorActions';

export const GET_TRANSLATIONS = '@account/get-translation';
export const SAVE_TRANSLATION = '@account/save-translation';
export const GET_TEMPLATE = '@account/get-template';
export const GET_TEMPLATES = '@account/get-templates';
export const SAVE_TEMPLATE = '@account/save-template';
export const GET_CONFIGURATIONS = '@account/get-configurations';
export const SAVE_CONFIGURATION = '@account/save-configuration';

export function getTranslations(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: TranslationsDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_TRANSLATIONS,
        payload: { translations: response.data.translations }
      });
    });
  };
}

export function saveTranslation(
  variables: SaveTranslationMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: SaveTranslationDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const saveTranslationMutation: SaveTranslationMutation = response.data;
        dispatch({
          type: SAVE_TRANSLATION,
          payload: { saveTranslation: saveTranslationMutation.saveTranslation }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getTemplates(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: TemplatesDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_TEMPLATES,
        payload: { templates: response.data.templates }
      });
    });
  };
}

export function getTemplate(
  templateQueryVariables: TemplateQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: TemplateDocument,
    variables: templateQueryVariables
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_TEMPLATE,
        payload: { template: response.data.template }
      });
    });
  };
}

export function saveTemplate(
  variables: SaveTemplateMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: SaveTemplateDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const saveTemplateMutation: SaveTemplateMutation = response.data;
        dispatch({
          type: SAVE_TEMPLATE,
          payload: { saveTranslation: saveTemplateMutation.saveTemplate }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getConfigurations(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: ConfigurationsDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_CONFIGURATIONS,
        payload: { configurations: response.data.configurations }
      });
    });
  };
}

export function saveConfiguration(
  variables: SaveConfigurationMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: SaveConfigurationDocument,
    variables: variables
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: SAVE_CONFIGURATION,
        payload: { templates: response.data.configuration }
      });
    });
  };
}
