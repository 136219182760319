import client from 'src/utils/apolloClient';
// src/thunoks.ts
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ADD_QUE_MESSAGE, handleResponseError } from '../actions/errorActions';
import { RootState } from 'src/store/storeInterface';

import {
  TicketsDocument,
  TicketDocument,
  TicketsQueryVariables,
  TicketQueryVariables,
  SaveTicketMutation,
  SaveTicketDocument,
  SaveTicketMutationVariables,
  WorkLogsForReportDocument,
  WorkLogsForReportQueryVariables,
  WorkLogsByTicketIdQueryVariables,
  WorkLogsByTicketIdDocument,
  WorkLogQueryVariables,
  WorkLogDocument,
  SaveWorkLogMutation,
  SaveWorkLogMutationVariables,
  SaveWorkLogDocument,
  WorkOptionsDocument
} from 'src/generated/graphql';
export const GET_TICKETS = '@ticket/get-tickets';
export const GET_TICKET = '@ticket/get-ticket';
export const SAVE_TICKET = '@ticket/save-ticket';
export const ADD_REDIRECT_TO_LIST = '@ticket/add-redirect-to-list';
export const GET_WORKLOGS_FOR_TICKET = '@ticket/get-worklogs-for-ticket';
export const GET_WORKLOGS_FOR_REPORT = '@ticket/get-worklogs-for-report';
export const CLEAR_WORKLOGS_FOR_REPORT = '@ticket/clear-worklogs-for-report';
export const GET_WORKLOG = '@ticket/get-worklog';
export const SAVE_WORKLOG = '@ticket/save-worklog';
export const GET_WORKOPTIONS = '@ticket/get-workoptions';
export const CLEAR_REDIRECT_TO_LIST = '@ticket/clear-redirect-to-list';
export const NONE = '@none';

export function getTickets(variables: TicketsQueryVariables): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({ query: TicketsDocument, variables: variables, fetchPolicy: 'no-cache' });
  return dispatch => {
    request.then(response => {
      const tickets: TicketsQueryVariables = response.data.ticketStream;
      dispatch({
        type: GET_TICKETS,
        payload: { tickets: tickets }
      });
    });
  };
}

export function getTicket(id: number): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: TicketDocument,
    variables: { id: id },
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    if (id === 0) {
      dispatch({
        type: GET_TICKET,
        payload: {
          ticket: {
            id: 0,
            status: 'Open',
            createdDate: new Date(),
            defaultWorkOptions: []
          }
        }
      });
    } else {
      request.then(response => {
        const ticket: TicketQueryVariables = response.data.ticket;
        dispatch({
          type: GET_TICKET,
          payload: { ticket: ticket }
        });
      });
    }
  };
}

export function saveTicket(
  variables: SaveTicketMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: SaveTicketDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const saveTicketMutation: SaveTicketMutation = response.data;
        dispatch({
          type: SAVE_TICKET,
          payload: { saveTicket: saveTicketMutation.saveTicket }
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Saved ticket  ${response.data.saveTicket.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getWorkLogsForTickets(
  variables: WorkLogsByTicketIdQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: WorkLogsByTicketIdDocument,
    variables: variables,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_WORKLOGS_FOR_TICKET,
        payload: { workLogsByTicketID: response.data.workLogsByTicketID }
      });
    });
  };
}

export function getWorkLogsForReport(
  variables: WorkLogsForReportQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  console.log(variables.from, variables.to);
  const request = client.query({
    query: WorkLogsForReportDocument,
    variables: variables,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_WORKLOGS_FOR_REPORT,
        payload: { workLogsForReport: response.data.workLogsForReport }
      });
    });
  };
}

export function clearWorkLogsForReports(): ThunkAction<void, RootState, unknown, Action<string>> {
  return dispatch => {
    dispatch({ type: CLEAR_WORKLOGS_FOR_REPORT });
  };
}
export function getWorkLog(variables: WorkLogQueryVariables): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: WorkLogDocument,
    variables: variables,
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    if (variables.id === 0) {
      dispatch({ type: GET_WORKLOG, payload: { workLog: { id: 0, createdDate: new Date() } } });
    } else {
      request.then(response => {
        dispatch({
          type: GET_WORKLOG,
          payload: { workLog: response.data.workLog }
        });
      });
    }
  };
}

export function saveWorkLog(
  variables: SaveWorkLogMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: SaveWorkLogDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const saveWorkLogMutation: SaveWorkLogMutation = response.data;
        dispatch({
          type: SAVE_WORKLOG,
          payload: { saveWorkLog: saveWorkLogMutation.saveWorkLog }
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Saved worklog ${saveWorkLogMutation.saveWorkLog?.id} on ticket ${variables.workLogInput.ticketID}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getWorkOptions(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: WorkOptionsDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_WORKOPTIONS,
        payload: { workOptions: response.data.workOptions }
      });
    });
  };
}

export function clearRedirectToList(message: {
  status: string;
  message: string;
}): ThunkAction<void, RootState, unknown, Action<string>> {
  return dispatch => {
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: message
      }
    });
    dispatch({ type: CLEAR_REDIRECT_TO_LIST, payload: {} });
  };
}
