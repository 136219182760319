import {
  AppBar,
  Box,
  Hidden,
  MenuItem,
  IconButton,
  makeStyles,
  Select,
  SvgIcon,
  Toolbar,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const [lang, setLang] = useState('de-CH');
  const { i18n, t } = useTranslation();
  const account = useSelector(state => state.account);

  const onLanguageHandle = event => {
    let newLang = event.target.value;
    setLang(newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton className={classes.menuButton} color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/app">
            <Logo user={account.user} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Select color="secondary" margin="dense" value={lang} onChange={onLanguageHandle} variant="outlined">
          <MenuItem value="en-GB">
            <Typography style={{ color: 'white' }}>{t('English')}</Typography>
          </MenuItem>
          <MenuItem value="de-CH">
            <Typography style={{ color: 'white' }}>{t('German')}</Typography>
          </MenuItem>
        </Select>
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
