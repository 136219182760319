import React from 'react';
import { COMPANY_LOGO_FILE } from 'src/constants';
import fileService from 'src/services/fileService';

function Logo(props) {
  return (
    <img
      style={{ maxHeight: 40 }}
      alt="Logo"
      src={props.user?.tenantID ? fileService.getFileUrl(props.user.tenantID, COMPANY_LOGO_FILE) : '/static/logo.svg'}
      {...props}
    />
  );
}

export default Logo;
