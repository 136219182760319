/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_ERROR,
  CLEAR_ERROR,
  CLEAR_ERRORS,
  ADD_QUE_MESSAGE,
  REMOVE_QUE_MESSAGES,
  REMOVE_QUE_MESSAGE
} from 'src/actions/errorActions';

const initialState = {
  errors: [],
  messages: []
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ERROR: {
      const { errorArray } = action.payload;
      return produce(state, draft => {
        errorArray.forEach(element => {
          draft.errors.push(element);
        });
      });
    }

    case REMOVE_QUE_MESSAGES: {
      return produce(state, draft => {
        draft.messages = [];
      });
    }

    case REMOVE_QUE_MESSAGE: {
      return produce(state, draft => {
        draft.messages = [];
        //        draft.messages = [];state.messages.filter(m => message !== m.text);
      });
    }

    case ADD_QUE_MESSAGE: {
      const { message } = action.payload;
      return produce(state, draft => {
        draft.messages.push(message);
      });
    }

    case CLEAR_ERROR: {
      const { error } = action.payload;
      return produce(state, draft => {
        draft.errors = draft.errors.filter(e => e.Message !== error);
      });
    }

    case CLEAR_ERRORS: {
      return produce(state, draft => {
        draft.errors = [];
      });
    }

    default: {
      return state;
    }
  }
};

export default errorReducer;
