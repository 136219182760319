import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'src/store/storeInterface';
import client from 'src/utils/apolloClient';
import {
  InvoiceDocument,
  InvoicesDocument,
  InvoiceQueryVariables,
  OpenInvoicesDocument,
  OpenInvoiceQueryVariables,
  OpenInvoiceDocument,
  CreateInvoiceMutationVariables,
  CreateInvoiceDocument,
  CreateInvoiceMutation,
  OpenOfferInvoiceQueryVariables,
  OpenOfferInvoiceDocument,
  OpenOfferInvoicesDocument,
  UpdateInvoiceMutationVariables,
  UpdateInvoiceDocument,
  UpdateInvoiceMutation
} from 'src/generated/graphql';
import { ADD_QUE_MESSAGE, handleResponseError } from './errorActions';

export const GET_INVOICE = '@invoice/get-invoice';
export const CREATE_INVOICE = '@invoice/create-invoice';
export const UPDATE_INVOICE = '@invoice/update-invoice';
export const GET_INVOICES = '@invoice/get-invoices';
export const GET_OPEN_INVOICE = '@invoice/get-open-invoice';
export const GET_OPEN_INVOICES = '@invoice/get-open-invoices';
export const GET_OPEN_OFFER_INVOICE = '@invoice/get-open-offer-invoice';
export const GET_OPEN_OFFER_INVOICES = '@invoice/get-open-offer-invoices';
export const ADD_REDIRECT_TO_LIST = '@invoice/add-redirect-to-list';

export function getInvoices(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: InvoicesDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_INVOICES,
        payload: { invoices: response.data.invoices }
      });
    });
  };
}

export function getOpenInvoices(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: OpenInvoicesDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_OPEN_INVOICES,
        payload: { openInvoices: response.data.openInvoices }
      });
    });
  };
}

export function getOpenOfferInvoices(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: OpenOfferInvoicesDocument
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_OPEN_OFFER_INVOICES,
        payload: { openOfferInvoices: response.data.openOfferInvoices }
      });
    });
  };
}

export function getOpenInvoice(
  openInvoiceQueryVariables: OpenInvoiceQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: OpenInvoiceDocument,
    variables: openInvoiceQueryVariables,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_OPEN_INVOICE,
        payload: { openInvoice: response.data.openInvoice }
      });
    });
  };
}

export function getOpenOfferInvoice(
  openOfferInvoiceQueryVariables: OpenOfferInvoiceQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: OpenOfferInvoiceDocument,
    variables: openOfferInvoiceQueryVariables,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_OPEN_OFFER_INVOICE,
        payload: { openOfferInvoice: response.data.openOfferInvoice }
      });
    });
  };
}

export function getInvoice(
  invoiceQueryVariables: InvoiceQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: InvoiceDocument,
    variables: invoiceQueryVariables
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_INVOICE,
        payload: { invoice: response.data.invoice }
      });
    });
  };
}

export function updateInvoice(
  variables: UpdateInvoiceMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: UpdateInvoiceDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const updateInvoiceMutation: UpdateInvoiceMutation = response.data;
        dispatch({
          type: UPDATE_INVOICE,
          payload: { updateInvoice: updateInvoiceMutation.updateInvoice }
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Update invoice ${updateInvoiceMutation.updateInvoice?.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function createInvoice(
  variables: CreateInvoiceMutationVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.mutate({
    mutation: CreateInvoiceDocument,
    variables: variables
  });
  return async dispatch => {
    await request.then(
      response => {
        const createInvoiceMutation: CreateInvoiceMutation = response.data;
        dispatch({
          type: CREATE_INVOICE,
          payload: { createInvoice: createInvoiceMutation.createInvoice }
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Create invoice ${createInvoiceMutation.createInvoice?.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

// TODO
