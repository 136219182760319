export const ADD_ERROR = '@error/add-error';
export const ADD_QUE_MESSAGE = '@error/add-que-message';
export const REMOVE_QUE_MESSAGES = '@error/remove-que-messages';
export const REMOVE_QUE_MESSAGE = '@error/remove-que-message';
export const CLEAR_ERRORS = '@error/clear-errors';
export const CLEAR_ERROR = '@error/clear-error';

export function addError(error) {
  return { type: ADD_ERROR, payload: error };
}

export function addQueMessage(message) {
  return { type: ADD_QUE_MESSAGE, payload: message };
}

export function removeQueMessage(message) {
  return { type: REMOVE_QUE_MESSAGE, payload: message };
}

export function removeQueMessages() {
  return { type: REMOVE_QUE_MESSAGES, payload: {} };
}

export function clearError(errorIndex) {
  return { type: CLEAR_ERROR, payload: { errorIndex: errorIndex } };
}

export function clearErrors() {
  return { type: CLEAR_ERRORS, payload: {} };
}

export function handleResponseError(error, dispatch) {
  error.networkError &&
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: {
          text: 'Error saving: ' + error.networkError,
          options: { variant: 'error' }
        }
      }
    });
  error.graphQLErrors &&
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: {
          text: 'Error saving: ' + error.graphQLErrors[0].Message,
          options: { variant: 'error' }
        }
      }
    });
  console.warn('Error saving: ');
}
