import { gql } from 'apollo-boost';
import jwtDecode from 'jwt-decode';
import { PATH_CONFIRM_REGISTRATION } from 'src/constants';
import client from 'src/utils/apolloClient';
import axios from 'src/utils/axios';

const USER = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      initials
      email
      mobile
      ahvNumber
      workload
      annualGrossSalary
      eligibleForLostHours
      hasDecisionMakingPosition
      tenantID
    }
  }
`;

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  validateToken = accessToken => {
    if (!this.isValidToken(accessToken)) {
      this.setSession(null);
      window.location.href = '/login';
    }
  };

  loginWithEmailAndPassword = (email, password, tenantCode) =>
    new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_LOGIN_URL, { email, password, tenantCode })
        .then(response => {
          if (response.statusText === 'OK') {
            this.setSession(response.data.accessToken);
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginWithExternal = accessToken =>
    new Promise((resolve, reject) => {
      if (!accessToken || accessToken === '') {
        reject('no token found');
      }
      this.setSession(accessToken);
      resolve();
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      client
        .query({ query: USER })
        .then(response => {
          if (response.data.currentUser) {
            resolve(response.data.currentUser);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  registerWithEmail = (companyName, email) =>
    new Promise((resolve, reject) => {
      const data = {
        companyName,
        email,
        confirmUrl: process.env.REACT_APP_PUBLIC_HOST + PATH_CONFIRM_REGISTRATION
      };

      axios
        .post(process.env.REACT_APP_REGISTRATION_URL, data)
        .then(response => {
          if (response.statusText === 'OK') {
            resolve();
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error.response);
        });
    });

  confirmRegistration = (email, newPassword, tenantCode, isInvitation) =>
    new Promise((resolve, reject) => {
      const data = {
        email,
        newPassword,
        tenantCode,
        loginUrl: `${process.env.REACT_APP_PUBLIC_HOST}/${tenantCode}`,
        isInvitation
      };

      axios
        .post(process.env.REACT_APP_ACTIVATION_URL, data)
        .then(response => {
          if (response.statusText === 'OK') {
            this.setSession(response.data.accessToken);
            resolve();
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
