import { gql } from 'apollo-boost';
import client from 'src/utils/apolloClient';

export const UPDATE_TENANT = '@account/update-tenant';
export const GET_USERGROUPS = '@account/get-usergroups';

const UPDATE_TENANT_MUTATION = gql`
  mutation UpdateTenant($tenantInput: TenantInput!) {
    updateTenant(tenantInput: $tenantInput) {
      id
    }
  }
`;

export function updateCompany(update) {
  const request = client.mutate({
    mutation: UPDATE_TENANT_MUTATION,
    variables: { tenantInput: update }
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: UPDATE_TENANT,
        payload: { user: response.data.updateTenant }
      });
    });
  };
}

const GET_USERGROUPS_QUERY = gql`
  query UserGroups {
    userGroups {
      id
      name
      description
    }
  }
`;

export function getUserGroups(update) {
  const request = client.query({
    query: GET_USERGROUPS_QUERY
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_USERGROUPS,
        payload: { userGroups: response.data.userGroups }
      });
    });
  };
}
