/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_TENANT } from 'src/actions/tenantActions';
import { UPDATE_TENANT } from 'src/actions/companyActions';

const initialState = {
  user: null
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TENANT: {
      const { tenant } = action.payload;
      return produce(state, draft => {
        draft.tenant = tenant;
      });
    }

    case UPDATE_TENANT: {
      const { tenant } = action.payload;
      return produce(state, draft => {
        draft.tenant = tenant;
      });
    }

    default: {
      return state;
    }
  }
};

export default tenantReducer;
