/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const PATH_CONFIRM_REGISTRATION = '/confirm-registration';

export const PATH_CONFIRM_INVITATION = '/confirm-invitation';

export const COMPANY_LOGO_FILE = 'company_logo';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^+&*])(?=.{8,})/;

export const PASSWORD_MAX_LENGTH = 255;

export const AHV_NUMBER_REGEX = /^[0-9]{3}.[0-9]{4}.[0-9]{4}.[0-9]{2}$/;

export const SALARY_NUMBER_REGEX = /^(?!0\d)\d*(\.\d{1,2})?$/;

export const PHONE_NUMBER_REGEX = /^0\d{7,14}$/;

export const BUSINESS_REGISTER_NUMBER_REGEX = /^[a-zA-Z]{3} [0-9]{3} [0-9]{3} [0-9]{3}$/;

export const IBAN_REGEX = /^[A-Z]{2}[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{1}$/;
