/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  BarChart as BarChartIcon,
  Layout as LayoutIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  Home as HomeIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Business activities',
    items: [
      {
        title: 'Daily tasks',
        icon: LayoutIcon,
        href: '/app/tasks/daily',
        items: [
          {
            title: 'My Tickets',
            href: '/app/tasks/my-tickets'
          },
          {
            title: 'All Tickets',
            href: '/app/tasks/tickets'
          },
          {
            title: 'My reports',
            href: '/app/tasks/report'
          }
        ]
      },
      {
        title: 'Manage Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Contracts',
            href: '/app/management/contracts'
          },
          {
            title: 'Offer',
            href: '/app/management/offers'
          },
          /*           {
            title: 'Tasks',
            href: '/app/management/tasks'
          }, */
          {
            title: 'Systems',
            href: '/app/management/systems'
          },
          {
            title: 'Bills',
            href: '/app/management/invoices'
          },
          {
            title: 'Create bills',
            href: '/app/management/open-invoices'
          }
        ]
      },
      {
        title: 'Reports',
        icon: BarChartIcon,
        href: '/app/management/reports',
        items: [
          {
            title: 'Work report',
            href: '/app/management/reports/work'
          },
          {
            title: 'Calender report',
            href: '/app/management/reports/calender'
          },
          {
            title: 'Custom reports',
            href: '/app/management/reports/generic'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Administration',
    items: [
      {
        title: 'Settings',
        href: '/app/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Company',
            icon: HomeIcon,
            items: [
              {
                title: 'Create new user',
                href: '/app/account/create'
              },
              {
                title: 'Manage users',
                href: '/app/accounts'
              },
              {
                title: 'Company details',
                href: '/app/company'
              }
            ]
          },
          {
            title: 'CMS',
            href: '/app/cms'
          },
          {
            title: 'Settings',
            href: '/app/settings'
          },
          {
            title: 'System Parameters',
            href: '/app/systemParameters'
          }
        ]
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box height="100%" display="flex" flexDirection="column">
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo user={user} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Box flexGrow={1}></Box>
        <Divider />
        <Box>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <img
              style={{ maxWidth: 150 }}
              src="/static/images/logo_saplane.png"
              alt="website logo"
              className={classes.saplaneLogo}
            />
            <Typography variant="body2" color="textPrimary">
              {t('(c) Kallysoft 2021')} <br />
              {t('Feel free to contact us in case of questions')} <br />
            </Typography>
          </Box>
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
