import ApolloClient from 'apollo-boost';
import authService from 'src/services/authService';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  request: operation => {
    const accessToken = localStorage.getItem('accessToken');
    authService.validateToken(accessToken);
    operation.setContext({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    });
  },
  onError: ({ networkError, graphQLErrors, response, operation }) => {
    if (graphQLErrors) {
      console.warn('graphQLErrors', graphQLErrors);
      //     response.handledErrors = graphQLErrors.map(e => e);
      //     response.errors = null;
      //     response.data = {};
    }
    if (networkError) {
      console.warn('networkError', networkError);
    }
    if (response) {
      console.warn('response', response);
    }
    if (operation) {
      console.warn('operation', operation);
    }
  }
});

export default client;
