import axios from 'src/utils/axios';
import { ADD_QUE_MESSAGE } from '../actions/errorActions';

export const UPLOAD_FILE = '@file/upload';
export const UPLOAD_SYSTEM_PARAM = '@file/upload-system-param';
export const UPLOAD_SYSTEM_PARAM_PREVIEW = '@file/upload-system-param-preview';
export function uploadFile(file) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const formData = new FormData();
  formData.append('content', file.content);
  formData.append('name', file.name);

  const request = axios.post(process.env.REACT_APP_FILES_URL, formData, config);

  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: UPLOAD_FILE
        });
      },
      error => {
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Error upload system parameters:` + error.response.data.detail,
              options: { variant: 'success' }
            }
          }
        });
      }
    );
  };
}

export function uploadSystemParametersPreview(file) {
  console.log(file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const formData = new FormData();
  formData.append('content', file.content);
  formData.append('name', file.name);

  const request = axios.post(process.env.REACT_APP_FILES_URL + '/SystemParametersPreview', formData, config);
  let result = { rows: 0, valid: false, error: undefined };
  return dispatch => {
    request.then(
      response => {
        result.valid = true;
        result.rows = response.data;
        result.error = undefined;
        dispatch({
          type: UPLOAD_SYSTEM_PARAM_PREVIEW,
          payload: { uploadPreview: result }
        });
      },
      error => {
        result.valid = false;
        result.error = error.response.data.detail;
        result.rows = 0;
        dispatch({
          type: UPLOAD_SYSTEM_PARAM_PREVIEW,
          payload: { uploadPreview: result }
        });
      }
    );
  };
}

export function uploadSystemParameters(file) {
  console.log(file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const formData = new FormData();
  formData.append('content', file.content);
  formData.append('name', file.name);

  const request = axios.post(process.env.REACT_APP_FILES_URL + '/SystemParameters', formData, config);

  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: UPLOAD_SYSTEM_PARAM
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Uploaded ${response.data} parameters`,
              options: { variant: 'success' }
            }
          }
        });
      },

      error => {
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Error upload system parameters:` + error.response.data.detail,
              options: { variant: 'error' }
            }
          }
        });
      }
    );
  };
}
