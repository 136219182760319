import { gql } from 'apollo-boost';
import client from 'src/utils/apolloClient';
import { ADD_QUE_MESSAGE, handleResponseError } from '../actions/errorActions';

export const UPDATE_SYSTEM = '@system/update-system';
export const SAVE_SYSTEM = '@system/save-system';
export const SAVE_SYSTEM_PARAMETER = '@system/save-system-parameter';
export const GET_SYSTEMS = '@system/get-systems';
export const GET_SEARCH_SYSTEMS = '@system/get-search-systems';
export const GET_SYSTEM = '@system/get-system';
export const GET_SYSTEMS_DETAILS = '@system/get-system-details';
export const GET_SYSTEM_PARAMS = '@system/get-system-params';
export const GET_SYSTEM_PARTS = '@system/get-system-parts';
export const GET_OWNERS = '@system/get-owners';
export const GET_ADMINS = '@system/get-admins';
export const GET_CUSTOMERS = '@system/get-customers';
export const SET_SYSTEM_OWNER = '@system/set-system-owner';
export const GET_PARTS = '@system/parts';
export const GET_SUPPLIERS = '@system/get-suppliers';

const fieldsOnSystem = gql`
  fragment fieldsOnSystem on SystemType {
    id
    code
    maintainerID
    owner {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
    customer {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
    administration {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
    address {
      id
      street
      zip
      city
      state
      country
    }
    remark
    parts {
      id
      partID
      systemID
      part {
        id
        name
        serialNumber
        supplier {
          id
          name
        }
      }
    }
    details {
      systemParam {
        id
        name
        visibleOnContract
        systemParamCategory {
          id
          name
        }
      }
      systemParamID
      value
    }
  }
`;

export const GET_SYSTEMS_QUERY = gql`
  query Systems($text: String) {
    systems(text: $text) {
      id
      code
      maintainer {
        id
        initials
      }
      customer {
        id
        name
      }
      address {
        id
        street
      }
      remark
    }
  }
`;

export const GET_SEARCH_SYSTEMS_QUERY = gql`
  query($text: String!) {
    searchSystemForTicket(text: $text) {
      system {
        id
        code
        remark
        address {
          id
          street
          zip
          city
        }
      }
      contracts {
        id
        internalRemark
      }
      offers {
        id
        remark
      }
    }
  }
`;

export const SAVE_SYSTEM_PARAMS_MUTATION = gql`
  mutation SaveSystemParameter($systemParamInput: SystemParamInput!, $modUser: String!) {
    saveSystemParameter(systemParamInput: $systemParamInput, modUser: $modUser) {
      id
      name
      price
      linkedLine
      basicCharge
      systemParamCategory {
        id
        name
      }
    }
  }
`;

export const GET_SYSTEM_PARAMS_QUERY = gql`
  query SystemParams {
    systemParams {
      id
      name
      price
      linkedLine
      basicCharge
      systemParamCategory {
        id
        name
      }
    }
  }
`;

export const GET_PARTS_QUERY = gql`
  query Parts {
    parts {
      id
      name
      serialNumber
      supplier {
        id
        name
      }
    }
  }
`;

export const GET_SYSTEM_DETAILS_QUERY = gql`
  query SystemDetails($id: Int!) {
    systemDetails(id: $id) {
      systemParam {
        id
        name
        price
        linkedLine
        basicCharge
        systemParamCategory {
          id
          name
        }
      }
      systemParamID
      value
    }
  }
`;

export const GET_OWNERS_QUERY = gql`
  query Owners {
    owners {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
  }
`;

export const GET_ADMINS_QUERY = gql`
  query Admins {
    admins {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
  }
`;

export const GET_CUSTOMERS_QUERY = gql`
  query Customers {
    customers {
      id
      name
      address {
        id
        street
        zip
        city
        state
        country
      }
    }
  }
`;
//   mutation UpdateSystem($systemInput: SystemInput!) {
//   updateSystem(systemInput: $systemInput) {
export const SAVE_SYSTEM_MUTATION = gql`
  mutation SaveSystem($systemInput: SystemInput!, $modUser: String!) {
    saveSystem(systemInput: $systemInput, modUser: $modUser) {
      ...fieldsOnSystem
    }
  }
  ${fieldsOnSystem}
`;

export const GET_SYSTEM_QUERY = gql`
  query System($id: Int!) {
    system(id: $id) {
      ...fieldsOnSystem
    }
  }
  ${fieldsOnSystem}
`;

const UPDATE_SYSTEM_MUTATION = gql`
  mutation UpdateSystem($systemInput: SystemInput!) {
    updateSystem(systemInput: $systemInput) {
      id
    }
  }
`;

export const GET_SUPPLIERS_QUERY = gql`
  query {
    suppliers {
      id
      name
    }
  }
`;

export function updateSystem(update) {
  const request = client.mutate({
    mutation: UPDATE_SYSTEM_MUTATION,
    variables: { systemInput: update }
  });
  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Saved system ${response.data.updateSystem.id}`,
              options: { variant: 'success' }
            }
          }
        });
        dispatch({
          type: UPDATE_SYSTEM,
          payload: { user: response.data.updateSystem }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getSystems() {
  const request = client.query({
    query: GET_SYSTEMS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SYSTEMS,
        payload: { systems: response.data.systems }
      });
    });
  };
}

export function getSearchSystems() {
  const request = client.query({
    query: GET_SEARCH_SYSTEMS_QUERY,
    variables: { text: '' },
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SEARCH_SYSTEMS,
        payload: { searchSystems: response.data.searchSystemForTicket }
      });
    });
  };
}

export function getSystem(id) {
  if (!id) {
    return { type: GET_SYSTEM, payload: { system: { id: 0, details: [] } } };
  }
  const request = client.query({
    query: GET_SYSTEM_QUERY,
    variables: { id: id },
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SYSTEM,
        payload: { system: response.data.system }
      });
    });
  };
}

export function setSystemOwner(owner) {
  if (typeof owner == 'string') {
    return { type: SET_SYSTEM_OWNER, payload: { owner: { id: 0, name: owner } } };
  }
  if (owner?.id) {
    return { type: SET_SYSTEM_OWNER, payload: { owner } };
  }
  return { type: SET_SYSTEM_OWNER, payload: { owner } };
}

export function getSystemParams() {
  const request = client.query({
    query: GET_SYSTEM_PARAMS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SYSTEM_PARAMS,
        payload: { systemParams: response.data.systemParams }
      });
    });
  };
}

export function getParts() {
  const request = client.query({
    query: GET_PARTS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_PARTS,
        payload: { parts: response.data.parts }
      });
    });
  };
}

export function getOwners() {
  const request = client.query({
    query: GET_OWNERS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_OWNERS,
        payload: { owners: response.data.owners }
      });
    });
  };
}

export function getCustomers() {
  const request = client.query({
    query: GET_CUSTOMERS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: { customers: response.data.customers }
      });
    });
  };
}

export function getAdmins() {
  const request = client.query({
    query: GET_ADMINS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_ADMINS,
        payload: { admins: response.data.admins }
      });
    });
  };
}

export function getSystemDetails(systemId) {
  const request = client.query({
    query: GET_SYSTEM_DETAILS_QUERY,
    variables: { id: systemId },
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SYSTEMS_DETAILS,
        payload: { systemDetails: response.data.systemDetails }
      });
    });
  };
}

export function saveSystemParameter(variables) {
  const request = client.mutate({
    mutation: SAVE_SYSTEM_PARAMS_MUTATION,
    variables: variables
  });
  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: SAVE_SYSTEM_PARAMETER,
          payload: { saveSystemParameter: response.data.saveSystemParameter }
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Saved system parameter ${response.data.saveSystemParameter.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function saveSystem(system, modUser) {
  const request = client.mutate({
    mutation: SAVE_SYSTEM_MUTATION,
    variables: { systemInput: system, modUser: modUser }
  });
  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: SAVE_SYSTEM,
          payload: { saveSystem: response.data.saveSystem }
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Saved system ${response.data.saveSystem.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getSuppliers() {
  const request = client.query({
    query: GET_SUPPLIERS_QUERY,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_SUPPLIERS,
        payload: { suppliers: response.data.suppliers }
      });
    });
  };
}
