import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: any;
  Uri: any;
  Guid: any;
  Short: any;
  UShort: any;
  UInt: any;
  Long: any;
  BigInt: any;
  ULong: any;
  Byte: any;
  SByte: any;
};

export type Query = {
  __typename?: 'Query';
  admins?: Maybe<Array<Maybe<AdministrationType>>>;
  cities?: Maybe<Array<Maybe<CityType>>>;
  configurations?: Maybe<Array<Maybe<ConfigurationType>>>;
  currentUser?: Maybe<UserType>;
  customers?: Maybe<Array<Maybe<CustomerType>>>;
  excelReport?: Maybe<ReportType>;
  invoice?: Maybe<InvoiceType>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  lastMaintenanceContractForSystem?: Maybe<MaintenanceContractType>;
  maintenanceContract?: Maybe<MaintenanceContractType>;
  maintenanceContracts?: Maybe<Array<Maybe<MaintenanceContractType>>>;
  offer?: Maybe<OfferType>;
  offers?: Maybe<Array<Maybe<OfferType>>>;
  openInvoice?: Maybe<InvoiceType>;
  openInvoices?: Maybe<Array<Maybe<OpenInvoiceType>>>;
  openOfferInvoice?: Maybe<InvoiceType>;
  openOfferInvoices?: Maybe<Array<Maybe<OpenOfferInvoiceType>>>;
  owners?: Maybe<Array<Maybe<OwnerType>>>;
  parts?: Maybe<Array<Maybe<PartType>>>;
  report?: Maybe<ReportType>;
  reports?: Maybe<Array<Maybe<ReportType>>>;
  searchSystem?: Maybe<Array<Maybe<SystemType>>>;
  searchSystemForTicket?: Maybe<Array<Maybe<TicketSystemType>>>;
  services?: Maybe<Array<Maybe<ServiceType>>>;
  suppliers?: Maybe<Array<Maybe<SupplierType>>>;
  system?: Maybe<SystemType>;
  systemDetails?: Maybe<Array<Maybe<SystemDetailType>>>;
  systemParams?: Maybe<Array<Maybe<SystemParamType>>>;
  systemParts?: Maybe<Array<Maybe<SystemPartType>>>;
  systems?: Maybe<Array<Maybe<SystemType>>>;
  systemsPaginated?: Maybe<SystemConnection>;
  template?: Maybe<TemplateType>;
  templates?: Maybe<Array<Maybe<TemplateType>>>;
  tenant?: Maybe<TenantType>;
  ticket?: Maybe<TicketType>;
  tickets?: Maybe<Array<Maybe<TicketType>>>;
  ticketStream?: Maybe<Array<Maybe<TicketType>>>;
  translations?: Maybe<Array<Maybe<TranslationType>>>;
  user?: Maybe<UserType>;
  userGroups?: Maybe<Array<Maybe<UserGroupType>>>;
  users?: Maybe<Array<Maybe<UserType>>>;
  workLog?: Maybe<WorkLogType>;
  workLogs?: Maybe<Array<Maybe<WorkLogType>>>;
  workLogsByTicketID?: Maybe<Array<Maybe<WorkLogType>>>;
  workLogsForReport?: Maybe<Array<Maybe<WorkLogType>>>;
  workOptions?: Maybe<Array<Maybe<WorkOptionType>>>;
};

export type QueryExcelReportArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryInvoiceArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryLastMaintenanceContractForSystemArgs = {
  systemID: Scalars['Int'];
};

export type QueryMaintenanceContractArgs = {
  id: Scalars['Int'];
};

export type QueryMaintenanceContractsArgs = {
  text?: Maybe<Scalars['String']>;
  status?: Maybe<MaintenanceContractStatus>;
};

export type QueryOfferArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryOffersArgs = {
  text?: Maybe<Scalars['String']>;
};

export type QueryOpenInvoiceArgs = {
  maintenanceContractID?: Maybe<Scalars['Int']>;
};

export type QueryOpenOfferInvoiceArgs = {
  offerID?: Maybe<Scalars['Int']>;
};

export type QueryReportArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QuerySearchSystemArgs = {
  text: Scalars['String'];
};

export type QuerySearchSystemForTicketArgs = {
  text: Scalars['String'];
};

export type QuerySystemArgs = {
  id: Scalars['Int'];
};

export type QuerySystemDetailsArgs = {
  id: Scalars['Int'];
};

export type QuerySystemPartsArgs = {
  id: Scalars['Int'];
};

export type QuerySystemsArgs = {
  text?: Maybe<Scalars['String']>;
};

export type QuerySystemsPaginatedArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  orderBy?: Maybe<SystemsOrderBy>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryTemplateArgs = {
  name: Scalars['String'];
};

export type QueryTenantArgs = {
  id: Scalars['Int'];
};

export type QueryTicketArgs = {
  id: Scalars['Int'];
};

export type QueryTicketStreamArgs = {
  userID?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<TicketStreamOrderBy>;
};

export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type QueryWorkLogArgs = {
  id: Scalars['Int'];
};

export type QueryWorkLogsByTicketIdArgs = {
  ticketID: Scalars['Int'];
};

export type QueryWorkLogsForReportArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type TicketType = {
  __typename?: 'TicketType';
  assignee?: Maybe<UserType>;
  assigneeID?: Maybe<Scalars['Int']>;
  createdDate: Scalars['DateTime'];
  defaultWorkOptions?: Maybe<Array<Maybe<TicketWorkOptionType>>>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  executionDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  maintenanceContract?: Maybe<MaintenanceContractType>;
  maintenanceContractID?: Maybe<Scalars['Int']>;
  offer?: Maybe<OfferType>;
  offerID?: Maybe<Scalars['Int']>;
  priority?: Maybe<TicketPriority>;
  remark?: Maybe<Scalars['String']>;
  source?: Maybe<TicketSource>;
  status?: Maybe<TicketStatus>;
  totalHours?: Maybe<Scalars['Decimal']>;
  type?: Maybe<Scalars['String']>;
};

export type OfferType = {
  __typename?: 'OfferType';
  createdDate: Scalars['DateTime'];
  creator?: Maybe<UserType>;
  creatorID: Scalars['Int'];
  currencyCode: Scalars['String'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  parts?: Maybe<Array<Maybe<OfferSystemPartType>>>;
  remark?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<OfferServiceType>>>;
  signedDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OfferStatus>;
  system?: Maybe<SystemType>;
  systemID: Scalars['Int'];
  totalPrice: Scalars['Decimal'];
  validityDate: Scalars['DateTime'];
};

export type SystemType = {
  __typename?: 'SystemType';
  address?: Maybe<SystemAddressType>;
  addressID?: Maybe<Scalars['Int']>;
  administration?: Maybe<AdministrationType>;
  administrationID?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  customer?: Maybe<CustomerType>;
  customerID?: Maybe<Scalars['Int']>;
  details?: Maybe<Array<Maybe<SystemDetailType>>>;
  id: Scalars['Int'];
  maintainer?: Maybe<UserType>;
  maintainerID?: Maybe<Scalars['Int']>;
  owner?: Maybe<OwnerType>;
  ownerID?: Maybe<Scalars['Int']>;
  parts?: Maybe<Array<Maybe<SystemPartType>>>;
  remark?: Maybe<Scalars['String']>;
};

export type OwnerType = {
  __typename?: 'OwnerType';
  address?: Maybe<OwnerAddressType>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type OwnerAddressType = {
  __typename?: 'OwnerAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  address?: Maybe<CustomerAddressType>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type CustomerAddressType = {
  __typename?: 'CustomerAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AdministrationType = {
  __typename?: 'AdministrationType';
  address?: Maybe<AdministrationAddressType>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type AdministrationAddressType = {
  __typename?: 'AdministrationAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  ahvNumber?: Maybe<Scalars['String']>;
  annualGrossSalary: Scalars['Decimal'];
  createDate: Scalars['DateTime'];
  eligibleForLostHours: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasDecisionMakingPosition: Scalars['Boolean'];
  id: Scalars['Int'];
  initials?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastLogin: Scalars['DateTime'];
  lastName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  tenantID: Scalars['Int'];
  userGroup?: Maybe<UserGroupType>;
  userGroupID?: Maybe<Scalars['Int']>;
  workload: Scalars['Int'];
};

export type UserGroupType = {
  __typename?: 'UserGroupType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type SystemAddressType = {
  __typename?: 'SystemAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SystemDetailType = {
  __typename?: 'SystemDetailType';
  systemID: Scalars['Int'];
  systemParam?: Maybe<SystemParamType>;
  systemParamID: Scalars['Int'];
  value: Scalars['Decimal'];
};

export type SystemParamType = {
  __typename?: 'SystemParamType';
  basicCharge: Scalars['Boolean'];
  id: Scalars['Int'];
  linkedLine?: Maybe<Scalars['String']>;
  maintenanceParam?: Maybe<MaintenanceParamType>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Decimal']>;
  systemParamCategory?: Maybe<SystemParamCategoryType>;
  systemParamCategoryID?: Maybe<Scalars['Int']>;
  visibleOnContract?: Maybe<Scalars['Boolean']>;
};

export type SystemParamCategoryType = {
  __typename?: 'SystemParamCategoryType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MaintenanceParamType = {
  __typename?: 'MaintenanceParamType';
  currencyCode: Scalars['String'];
  id: Scalars['Int'];
  price: Scalars['Decimal'];
};

export type SystemPartType = {
  __typename?: 'SystemPartType';
  id: Scalars['Int'];
  part?: Maybe<PartType>;
  partID: Scalars['Int'];
  systemID: Scalars['Int'];
};

export type PartType = {
  __typename?: 'PartType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  serialNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierType>;
  supplierID?: Maybe<Scalars['Int']>;
};

export type SupplierType = {
  __typename?: 'SupplierType';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum OfferStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED'
}

export type OfferSystemPartType = {
  __typename?: 'OfferSystemPartType';
  currencyCode: Scalars['String'];
  offeredPrice: Scalars['Decimal'];
  offerID: Scalars['Int'];
  quantity: Scalars['Decimal'];
  systemPart?: Maybe<SystemPartType>;
  systemPartID: Scalars['Int'];
};

export type OfferServiceType = {
  __typename?: 'OfferServiceType';
  currencyCode: Scalars['String'];
  hours: Scalars['Decimal'];
  offeredPrice: Scalars['Decimal'];
  offerID: Scalars['Int'];
  sequence: Scalars['Int'];
  service?: Maybe<ServiceType>;
  serviceID: Scalars['Int'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
  serviceCategory?: Maybe<ServiceCategoryType>;
  serviceCategoryID: Scalars['Int'];
  visibleOnOffer: Scalars['Boolean'];
};

export type ServiceCategoryType = {
  __typename?: 'ServiceCategoryType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MaintenanceContractType = {
  __typename?: 'MaintenanceContractType';
  amountOfInspections: Scalars['Int'];
  asmBasis: Scalars['String'];
  asmIndex: Scalars['Decimal'];
  asmStatus: Scalars['String'];
  createdDate: Scalars['DateTime'];
  creator?: Maybe<UserType>;
  creatorID: Scalars['Int'];
  currencyCode: Scalars['String'];
  details?: Maybe<Array<Maybe<MaintenanceContractDetailType>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  fullMaintenanceFactor: Scalars['Decimal'];
  id: Scalars['Int'];
  internalRemark?: Maybe<Scalars['String']>;
  offerFactor: Scalars['Decimal'];
  paymentConditions?: Maybe<Scalars['String']>;
  signatory?: Maybe<UserType>;
  signatoryID?: Maybe<Scalars['Int']>;
  signedDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  status?: Maybe<MaintenanceContractStatus>;
  system?: Maybe<SystemType>;
  systemID: Scalars['Int'];
  totalPrice: Scalars['Decimal'];
};

export enum MaintenanceContractStatus {
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Ongoing = 'ONGOING',
  Terminated = 'TERMINATED'
}

export type MaintenanceContractDetailType = {
  __typename?: 'MaintenanceContractDetailType';
  contractPrice: Scalars['Decimal'];
  currencyCode?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  systemParam?: Maybe<SystemParamType>;
  systemParamID: Scalars['Int'];
  value: Scalars['Decimal'];
};

export enum TicketPriority {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum TicketStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum TicketSource {
  Mobile = 'MOBILE',
  Scheduler = 'SCHEDULER'
}

export type TicketWorkOptionType = {
  __typename?: 'TicketWorkOptionType';
  ticketID: Scalars['Int'];
  workOption?: Maybe<WorkOptionType>;
  workOptionID: Scalars['Int'];
};

export type WorkOptionType = {
  __typename?: 'WorkOptionType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum TicketStreamOrderBy {
  Priority = 'PRIORITY',
  User = 'USER',
  Status = 'STATUS'
}

export type WorkLogType = {
  __typename?: 'WorkLogType';
  fromDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  logType?: Maybe<WorkLogLogType>;
  parts?: Maybe<Array<Maybe<WorkLogSystemPartType>>>;
  remark: Scalars['String'];
  ticket?: Maybe<TicketType>;
  ticketID: Scalars['Int'];
  toDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserType>;
  userID: Scalars['Int'];
  workOptions?: Maybe<Array<Maybe<WorkLogWorkOptionType>>>;
};

export enum WorkLogLogType {
  User = 'USER',
  Audit = 'AUDIT'
}

export type WorkLogSystemPartType = {
  __typename?: 'WorkLogSystemPartType';
  quantity: Scalars['Decimal'];
  systemPart?: Maybe<SystemPartType>;
  systemPartID: Scalars['Int'];
  workLogID: Scalars['Int'];
};

export type WorkLogWorkOptionType = {
  __typename?: 'WorkLogWorkOptionType';
  workLogID: Scalars['Int'];
  workOption?: Maybe<WorkOptionType>;
  workOptionID: Scalars['Int'];
};

/** A connection from an object to a list of objects of type `System`. */
export type SystemConnection = {
  __typename?: 'SystemConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SystemEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<SystemType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An edge in a connection from an object to another object of type `System`. */
export type SystemEdge = {
  __typename?: 'SystemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SystemType>;
};

export enum SystemsOrderBy {
  Code = 'CODE'
}

export type TicketSystemType = {
  __typename?: 'TicketSystemType';
  contracts?: Maybe<Array<Maybe<MaintenanceContractType>>>;
  offers?: Maybe<Array<Maybe<OfferType>>>;
  system?: Maybe<SystemType>;
};

export type CityType = {
  __typename?: 'CityType';
  country: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['Int'];
};

export type ConfigurationType = {
  __typename?: 'ConfigurationType';
  defaultValue: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  options?: Maybe<Array<Maybe<ConfigurationOptionType>>>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigurationOptionType = {
  __typename?: 'ConfigurationOptionType';
  description: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
  label: Scalars['String'];
  sequence: Scalars['Int'];
};

export type TenantType = {
  __typename?: 'TenantType';
  active: Scalars['Boolean'];
  address?: Maybe<TenantAddressType>;
  businessRegisterNumber?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responsiblePerson?: Maybe<Scalars['String']>;
};

export type TenantAddressType = {
  __typename?: 'TenantAddressType';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type TranslationType = {
  __typename?: 'TranslationType';
  id: Scalars['Int'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  page?: Maybe<Scalars['String']>;
  translatedValue: Scalars['String'];
};

export type TemplateType = {
  __typename?: 'TemplateType';
  content: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  chargedFrom?: Maybe<Scalars['DateTime']>;
  chargedUntil?: Maybe<Scalars['DateTime']>;
  currencyCode: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  invoiceAmountExcl: Scalars['Decimal'];
  invoiceAmountIncl: Scalars['Decimal'];
  invoiceDate: Scalars['DateTime'];
  invoiceLines?: Maybe<Array<Maybe<InvoiceLineType>>>;
  maintenanceContract?: Maybe<MaintenanceContractType>;
  maintenanceContractID?: Maybe<Scalars['Int']>;
  offer?: Maybe<OfferType>;
  offerID?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type InvoiceLineType = {
  __typename?: 'InvoiceLineType';
  description: Scalars['String'];
  id: Scalars['Int'];
  itemPrice?: Maybe<Scalars['Decimal']>;
  lineAmountExcl?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Decimal']>;
};

export type OpenInvoiceType = {
  __typename?: 'OpenInvoiceType';
  customerName: Scalars['String'];
  daysToPay: Scalars['Int'];
  id: Scalars['Int'];
  lastPayment: Scalars['DateTime'];
  price: Scalars['Decimal'];
  systemCode: Scalars['String'];
  yearlyPrice: Scalars['Decimal'];
};

export type OpenOfferInvoiceType = {
  __typename?: 'OpenOfferInvoiceType';
  customerName: Scalars['String'];
  hours?: Maybe<Scalars['Decimal']>;
  id: Scalars['Int'];
  parts?: Maybe<Scalars['Decimal']>;
  remark: Scalars['String'];
  systemCode: Scalars['String'];
};

export type ReportType = {
  __typename?: 'ReportType';
  binaryData?: Maybe<Array<Scalars['Byte']>>;
  dataJSON?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  lastViewing?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfViews?: Maybe<Scalars['Int']>;
  reportSQL: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changeMaintenanceContract?: Maybe<MaintenanceContractType>;
  changeOffer?: Maybe<OfferType>;
  changePassword?: Maybe<UserType>;
  changeStatus?: Maybe<UserType>;
  closeTicket?: Maybe<TicketType>;
  copyMaintenanceContract?: Maybe<MaintenanceContractType>;
  createInvoice?: Maybe<InvoiceType>;
  deleteTicket?: Maybe<Scalars['String']>;
  deleteTranslation?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  deleteUserGroup?: Maybe<Scalars['String']>;
  deleteWorkLog?: Maybe<Scalars['String']>;
  errors?: Maybe<ErrorMessages>;
  fillWorkLogs?: Maybe<Scalars['String']>;
  saveConfiguration?: Maybe<ConfigurationType>;
  saveMaintenanceContract?: Maybe<MaintenanceContractType>;
  saveOffer?: Maybe<OfferType>;
  saveSystem?: Maybe<SystemType>;
  saveSystemParameter?: Maybe<SystemParamType>;
  saveTemplate?: Maybe<TemplateType>;
  saveTicket?: Maybe<TicketType>;
  saveTranslation?: Maybe<TranslationType>;
  saveUser?: Maybe<UserType>;
  saveUserGroup?: Maybe<UserGroupType>;
  saveWorkLog?: Maybe<WorkLogType>;
  updateInvoice?: Maybe<InvoiceType>;
  updateSystem?: Maybe<SystemType>;
  updateTenant?: Maybe<TenantType>;
  updateUser?: Maybe<UserType>;
};

export type MutationChangeMaintenanceContractArgs = {
  id: Scalars['Int'];
  status: MaintenanceContractStatus;
  remark?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  modUser: Scalars['String'];
};

export type MutationChangeOfferArgs = {
  id: Scalars['Int'];
  status: OfferStatus;
  date: Scalars['DateTime'];
  modUser: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  modUser: Scalars['String'];
};

export type MutationChangeStatusArgs = {
  newStatus: Scalars['Boolean'];
  userId: Scalars['Int'];
  modUser: Scalars['String'];
};

export type MutationCloseTicketArgs = {
  id: Scalars['Int'];
  modUser: Scalars['String'];
};

export type MutationCopyMaintenanceContractArgs = {
  id: Scalars['Int'];
  modUser: Scalars['String'];
};

export type MutationCreateInvoiceArgs = {
  invoiceInput: InvoiceInput;
  modUser: Scalars['String'];
};

export type MutationDeleteTicketArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteWorkLogArgs = {
  id: Scalars['Int'];
};

export type MutationFillWorkLogsArgs = {
  fillWorkLogsInput: FillWorkLogsInput;
  modUser: Scalars['String'];
};

export type MutationSaveConfigurationArgs = {
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  modUser: Scalars['String'];
};

export type MutationSaveMaintenanceContractArgs = {
  maintenanceContractInput: MaintenanceContractInput;
  modUser: Scalars['String'];
};

export type MutationSaveOfferArgs = {
  offerInput: OfferInput;
  modUser: Scalars['String'];
};

export type MutationSaveSystemArgs = {
  systemInput: SystemInput;
  modUser: Scalars['String'];
};

export type MutationSaveSystemParameterArgs = {
  systemParamInput: SystemParamInput;
  modUser: Scalars['String'];
};

export type MutationSaveTemplateArgs = {
  templateInput: TemplateInput;
};

export type MutationSaveTicketArgs = {
  ticketInput: TicketInput;
  modUser: Scalars['String'];
};

export type MutationSaveTranslationArgs = {
  translationInput: TranslationInput;
  modUser: Scalars['String'];
};

export type MutationSaveUserArgs = {
  userInput: UserInput;
  modUser: Scalars['String'];
  confirmUrl?: Maybe<Scalars['String']>;
};

export type MutationSaveUserGroupArgs = {
  userGroupInput: UserGroupInput;
  modUser: Scalars['String'];
};

export type MutationSaveWorkLogArgs = {
  workLogInput: WorkLogInput;
};

export type MutationUpdateInvoiceArgs = {
  invoiceId: Scalars['Int'];
  status: Scalars['String'];
  modUser: Scalars['String'];
};

export type MutationUpdateSystemArgs = {
  systemInput: SystemInput;
};

export type MutationUpdateTenantArgs = {
  tenantInput: TenantInput;
};

export type MutationUpdateUserArgs = {
  userUpdateInput: UserUpdateInput;
};

export type SystemInput = {
  id: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  ownerID?: Maybe<Scalars['Int']>;
  owner?: Maybe<OwnerInput>;
  customerID?: Maybe<Scalars['Int']>;
  customer?: Maybe<CustomerInput>;
  administrationID?: Maybe<Scalars['Int']>;
  administration?: Maybe<AdministrationInput>;
  addressID?: Maybe<Scalars['Int']>;
  address?: Maybe<SystemAddressInput>;
  maintainerID?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<SystemDetailInput>>>;
  parts?: Maybe<Array<Maybe<SystemPartInput>>>;
};

export type OwnerInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<OwnerAddressInput>;
};

export type OwnerAddressInput = {
  id: Scalars['Int'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<CustomerAddressInput>;
};

export type CustomerAddressInput = {
  id: Scalars['Int'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AdministrationInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<CustomerAddressInput>;
};

export type SystemAddressInput = {
  id: Scalars['Int'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type SystemDetailInput = {
  value?: Maybe<Scalars['Decimal']>;
  systemParamID: Scalars['Int'];
};

export type SystemPartInput = {
  id: Scalars['Int'];
  partID: Scalars['Int'];
  systemID: Scalars['Int'];
  part?: Maybe<PartInput>;
};

export type PartInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierInput>;
};

export type SupplierInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type SystemParamInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  basicCharge: Scalars['Boolean'];
  systemParamCategoryID?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  linkedLine?: Maybe<Scalars['String']>;
  visibleOnContract?: Maybe<Scalars['Boolean']>;
  systemParamCategory?: Maybe<SystemParamCategroryInput>;
};

export type SystemParamCategroryInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoiceInput = {
  id: Scalars['Int'];
  invoiceAmountIncl: Scalars['Decimal'];
  invoiceAmountExcl: Scalars['Decimal'];
  invoiceDate: Scalars['DateTime'];
  chargedFrom: Scalars['DateTime'];
  chargedUntil: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  status: Scalars['String'];
  maintenanceContractID?: Maybe<Scalars['Int']>;
  offerID?: Maybe<Scalars['Int']>;
  dueDate: Scalars['DateTime'];
  type: Scalars['String'];
  invoiceLines?: Maybe<Array<Maybe<InvoiceLineInput>>>;
};

export type InvoiceLineInput = {
  id: Scalars['Int'];
  itemPrice?: Maybe<Scalars['Decimal']>;
  lineAmountExcl?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Decimal']>;
  description: Scalars['String'];
};

export type WorkLogInput = {
  id: Scalars['Int'];
  ticketID: Scalars['Int'];
  userID: Scalars['Int'];
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  remark: Scalars['String'];
  logType: WorkLogLogType;
  parts?: Maybe<Array<Maybe<WorkLogSystemPartInput>>>;
  workOptions: Array<Maybe<WorkLogWorkOptionInput>>;
};

export type WorkLogSystemPartInput = {
  workLogID: Scalars['Int'];
  systemPartID: Scalars['Int'];
  systemPart?: Maybe<SystemPartOfferInput>;
  quantity: Scalars['Decimal'];
};

export type SystemPartOfferInput = {
  id: Scalars['Int'];
  systemID: Scalars['Int'];
  partID: Scalars['Int'];
  part?: Maybe<PartInput>;
};

export type WorkLogWorkOptionInput = {
  workLogID: Scalars['Int'];
  workOptionID: Scalars['Int'];
};

export type FillWorkLogsInput = {
  userID: Scalars['Int'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  fillType: FillWorkLogType;
  days?: Maybe<Array<Maybe<DayOfWeek>>>;
  percentageLost: Scalars['Float'];
};

export enum FillWorkLogType {
  AutoFill = 'AUTO_FILL',
  PercentageLost = 'PERCENTAGE_LOST',
  WeekDaysLost = 'WEEK_DAYS_LOST'
}

export enum DayOfWeek {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type OfferInput = {
  id: Scalars['Int'];
  systemID: Scalars['Int'];
  creatorID: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  validityDate: Scalars['DateTime'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  status: OfferStatus;
  totalPrice: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<OfferServiceInput>>>;
  parts?: Maybe<Array<Maybe<OfferSystemPartInput>>>;
};

export type OfferServiceInput = {
  serviceID: Scalars['Int'];
  offerID: Scalars['Int'];
  offeredPrice: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  hours: Scalars['Decimal'];
};

export type OfferSystemPartInput = {
  systemPartID: Scalars['Int'];
  offerID: Scalars['Int'];
  quantity: Scalars['Decimal'];
  offeredPrice: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  systemPart?: Maybe<SystemPartOfferInput>;
};

export type TicketInput = {
  id: Scalars['Int'];
  offerID?: Maybe<Scalars['Int']>;
  maintenanceContractID?: Maybe<Scalars['Int']>;
  priority: TicketPriority;
  remark: Scalars['String'];
  description: Scalars['String'];
  status: TicketStatus;
  createdDate: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['DateTime']>;
  executionDate?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  assigneeID?: Maybe<Scalars['Int']>;
  source: TicketSource;
  defaultWorkOptions: Array<Maybe<TicketWorkOptionInput>>;
};

export type TicketWorkOptionInput = {
  ticketID: Scalars['Int'];
  workOptionID: Scalars['Int'];
};

export type MaintenanceContractInput = {
  id: Scalars['Int'];
  systemID: Scalars['Int'];
  creatorID: Scalars['Int'];
  startDate: Scalars['DateTime'];
  asmIndex: Scalars['Decimal'];
  asmStatus: Scalars['String'];
  asmBasis: Scalars['String'];
  totalPrice: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  offerFactor: Scalars['Decimal'];
  fullMaintenanceFactor: Scalars['Decimal'];
  internalRemark?: Maybe<Scalars['String']>;
  paymentConditions?: Maybe<Scalars['String']>;
  amountOfInspections: Scalars['Int'];
  details?: Maybe<Array<Maybe<MaintenanceContractDetailInput>>>;
};

export type MaintenanceContractDetailInput = {
  systemParamID: Scalars['Int'];
  value: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  currencyCode?: Maybe<Scalars['String']>;
  systemParam?: Maybe<SystemParamInput>;
};

export type UserInput = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ahvNumber?: Maybe<Scalars['String']>;
  workload: Scalars['Int'];
  annualGrossSalary: Scalars['Decimal'];
  eligibleForLostHours: Scalars['Boolean'];
  hasDecisionMakingPosition: Scalars['Boolean'];
  userGroupID?: Maybe<Scalars['Int']>;
  userGroup?: Maybe<UserGroupInput>;
};

export type UserGroupInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type UserUpdateInput = {
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ahvNumber?: Maybe<Scalars['String']>;
  workload?: Maybe<Scalars['Int']>;
  annualGrossSalary?: Maybe<Scalars['Decimal']>;
  eligibleForLostHours?: Maybe<Scalars['Boolean']>;
  hasDecisionMakingPosition?: Maybe<Scalars['Boolean']>;
  userGroupID?: Maybe<Scalars['Int']>;
};

export type TenantInput = {
  id: Scalars['Int'];
  businessRegisterNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responsiblePerson?: Maybe<Scalars['String']>;
  address?: Maybe<TenantAddressInput>;
};

export type TenantAddressInput = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type TranslationInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  translatedValue: Scalars['String'];
  languageCode: Scalars['String'];
  page?: Maybe<Scalars['String']>;
};

export type TemplateInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  content: Scalars['String'];
};

export enum ErrorMessages {
  ErrorDeleteEntityInUse = 'ERROR_DELETE_ENTITY_IN_USE'
}

export type ConfigurationsQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigurationsQuery = { __typename?: 'Query' } & {
  configurations?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ConfigurationType' } & Pick<
          ConfigurationType,
          'id' | 'name' | 'value' | 'defaultValue' | 'type' | 'description'
        > & {
            options?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ConfigurationOptionType' } & Pick<
                    ConfigurationOptionType,
                    'id' | 'key' | 'label' | 'description' | 'sequence'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type CreateInvoiceMutationVariables = Exact<{
  invoiceInput: InvoiceInput;
  modUser: Scalars['String'];
}>;

export type CreateInvoiceMutation = { __typename?: 'Mutation' } & {
  createInvoice?: Maybe<
    { __typename?: 'InvoiceType' } & Pick<
      InvoiceType,
      | 'id'
      | 'invoiceDate'
      | 'maintenanceContractID'
      | 'offerID'
      | 'status'
      | 'invoiceAmountExcl'
      | 'invoiceAmountIncl'
      | 'currencyCode'
      | 'type'
      | 'dueDate'
      | 'chargedUntil'
      | 'chargedFrom'
    > & {
        invoiceLines?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceLineType' } & Pick<
                InvoiceLineType,
                'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
              >
            >
          >
        >;
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<
            MaintenanceContractType,
            'id' | 'totalPrice' | 'systemID'
          > & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type ExcelReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ExcelReportQuery = { __typename?: 'Query' } & {
  excelReport?: Maybe<
    { __typename?: 'ReportType' } & Pick<
      ReportType,
      'id' | 'name' | 'description' | 'lastViewing' | 'numberOfViews' | 'binaryData'
    >
  >;
};

export type InvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type InvoiceQuery = { __typename?: 'Query' } & {
  invoice?: Maybe<
    { __typename?: 'InvoiceType' } & Pick<
      InvoiceType,
      | 'id'
      | 'invoiceDate'
      | 'maintenanceContractID'
      | 'offerID'
      | 'status'
      | 'invoiceAmountExcl'
      | 'invoiceAmountIncl'
      | 'currencyCode'
      | 'type'
      | 'dueDate'
      | 'chargedUntil'
      | 'chargedFrom'
    > & {
        invoiceLines?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceLineType' } & Pick<
                InvoiceLineType,
                'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
              >
            >
          >
        >;
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<
            MaintenanceContractType,
            'id' | 'totalPrice' | 'systemID'
          > & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                    maintainer?: Maybe<
                      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                    maintainer?: Maybe<
                      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type InvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type InvoicesQuery = { __typename?: 'Query' } & {
  invoices?: Maybe<
    Array<
      Maybe<
        { __typename?: 'InvoiceType' } & Pick<
          InvoiceType,
          | 'id'
          | 'invoiceDate'
          | 'maintenanceContractID'
          | 'offerID'
          | 'status'
          | 'invoiceAmountExcl'
          | 'invoiceAmountIncl'
          | 'currencyCode'
          | 'type'
          | 'dueDate'
          | 'chargedUntil'
          | 'chargedFrom'
        > & {
            invoiceLines?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'InvoiceLineType' } & Pick<
                    InvoiceLineType,
                    'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
                  >
                >
              >
            >;
            maintenanceContract?: Maybe<
              { __typename?: 'MaintenanceContractType' } & Pick<
                MaintenanceContractType,
                'id' | 'totalPrice' | 'systemID'
              > & {
                  system?: Maybe<
                    { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                        customer?: Maybe<
                          { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                              address?: Maybe<
                                { __typename?: 'CustomerAddressType' } & Pick<
                                  CustomerAddressType,
                                  'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                                >
                              >;
                            }
                        >;
                        maintainer?: Maybe<
                          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                        >;
                      }
                  >;
                }
            >;
            offer?: Maybe<
              { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
                  system?: Maybe<
                    { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                        customer?: Maybe<
                          { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                              address?: Maybe<
                                { __typename?: 'CustomerAddressType' } & Pick<
                                  CustomerAddressType,
                                  'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                                >
                              >;
                            }
                        >;
                        maintainer?: Maybe<
                          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                        >;
                      }
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type OpenInvoiceQueryVariables = Exact<{
  maintenanceContractID: Scalars['Int'];
}>;

export type OpenInvoiceQuery = { __typename?: 'Query' } & {
  openInvoice?: Maybe<
    { __typename?: 'InvoiceType' } & Pick<
      InvoiceType,
      | 'id'
      | 'invoiceDate'
      | 'maintenanceContractID'
      | 'offerID'
      | 'status'
      | 'invoiceAmountExcl'
      | 'invoiceAmountIncl'
      | 'currencyCode'
      | 'type'
      | 'dueDate'
      | 'chargedUntil'
      | 'chargedFrom'
    > & {
        invoiceLines?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceLineType' } & Pick<
                InvoiceLineType,
                'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
              >
            >
          >
        >;
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<
            MaintenanceContractType,
            'id' | 'totalPrice' | 'systemID'
          > & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                    maintainer?: Maybe<
                      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                    maintainer?: Maybe<
                      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type OpenInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type OpenInvoicesQuery = { __typename?: 'Query' } & {
  openInvoices?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OpenInvoiceType' } & Pick<
          OpenInvoiceType,
          'id' | 'daysToPay' | 'yearlyPrice' | 'price' | 'customerName' | 'systemCode'
        >
      >
    >
  >;
};

export type OpenOfferInvoiceQueryVariables = Exact<{
  offerID: Scalars['Int'];
}>;

export type OpenOfferInvoiceQuery = { __typename?: 'Query' } & {
  openOfferInvoice?: Maybe<
    { __typename?: 'InvoiceType' } & Pick<
      InvoiceType,
      | 'id'
      | 'invoiceDate'
      | 'maintenanceContractID'
      | 'offerID'
      | 'status'
      | 'invoiceAmountExcl'
      | 'invoiceAmountIncl'
      | 'currencyCode'
      | 'type'
      | 'dueDate'
      | 'chargedUntil'
      | 'chargedFrom'
    > & {
        invoiceLines?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceLineType' } & Pick<
                InvoiceLineType,
                'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
              >
            >
          >
        >;
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<
            MaintenanceContractType,
            'id' | 'totalPrice' | 'systemID'
          > & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type OpenOfferInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type OpenOfferInvoicesQuery = { __typename?: 'Query' } & {
  openOfferInvoices?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OpenOfferInvoiceType' } & Pick<
          OpenOfferInvoiceType,
          'id' | 'remark' | 'hours' | 'parts' | 'customerName' | 'systemCode'
        >
      >
    >
  >;
};

export type ReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ReportQuery = { __typename?: 'Query' } & {
  report?: Maybe<
    { __typename?: 'ReportType' } & Pick<
      ReportType,
      'id' | 'name' | 'description' | 'lastViewing' | 'numberOfViews' | 'dataJSON'
    >
  >;
};

export type ReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsQuery = { __typename?: 'Query' } & {
  reports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ReportType' } & Pick<
          ReportType,
          'id' | 'name' | 'description' | 'lastViewing' | 'numberOfViews'
        >
      >
    >
  >;
};

export type SaveConfigurationMutationVariables = Exact<{
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  modUser: Scalars['String'];
}>;

export type SaveConfigurationMutation = { __typename?: 'Mutation' } & {
  saveConfiguration?: Maybe<
    { __typename?: 'ConfigurationType' } & Pick<
      ConfigurationType,
      'id' | 'name' | 'value' | 'defaultValue' | 'type' | 'description'
    > & {
        options?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ConfigurationOptionType' } & Pick<
                ConfigurationOptionType,
                'id' | 'key' | 'label' | 'description' | 'sequence'
              >
            >
          >
        >;
      }
  >;
};

export type SaveTemplateMutationVariables = Exact<{
  templateInput: TemplateInput;
}>;

export type SaveTemplateMutation = { __typename?: 'Mutation' } & {
  saveTemplate?: Maybe<{ __typename?: 'TemplateType' } & Pick<TemplateType, 'id' | 'name' | 'content'>>;
};

export type SaveTicketMutationVariables = Exact<{
  ticketInput: TicketInput;
  modUser: Scalars['String'];
}>;

export type SaveTicketMutation = { __typename?: 'Mutation' } & {
  saveTicket?: Maybe<
    { __typename?: 'TicketType' } & Pick<
      TicketType,
      | 'id'
      | 'offerID'
      | 'maintenanceContractID'
      | 'createdDate'
      | 'dueDate'
      | 'executionDate'
      | 'priority'
      | 'status'
      | 'remark'
      | 'description'
      | 'source'
      | 'type'
      | 'assigneeID'
      | 'totalHours'
    > & {
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<MaintenanceContractType, 'id'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                    customer?: Maybe<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'>>;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                    customer?: Maybe<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'>>;
                  }
              >;
            }
        >;
        assignee?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            | 'id'
            | 'firstName'
            | 'lastName'
            | 'initials'
            | 'email'
            | 'mobile'
            | 'ahvNumber'
            | 'workload'
            | 'annualGrossSalary'
            | 'eligibleForLostHours'
            | 'hasDecisionMakingPosition'
            | 'createDate'
            | 'lastLogin'
            | 'isActive'
          >
        >;
        defaultWorkOptions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TicketWorkOptionType' } & Pick<TicketWorkOptionType, 'ticketID' | 'workOptionID'> & {
                  workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                }
            >
          >
        >;
      }
  >;
};

export type SaveTranslationMutationVariables = Exact<{
  translationInput: TranslationInput;
  modUser: Scalars['String'];
}>;

export type SaveTranslationMutation = { __typename?: 'Mutation' } & {
  saveTranslation?: Maybe<
    { __typename?: 'TranslationType' } & Pick<
      TranslationType,
      'id' | 'languageCode' | 'name' | 'page' | 'translatedValue'
    >
  >;
};

export type SaveWorkLogMutationVariables = Exact<{
  workLogInput: WorkLogInput;
}>;

export type SaveWorkLogMutation = { __typename?: 'Mutation' } & {
  saveWorkLog?: Maybe<
    { __typename?: 'WorkLogType' } & Pick<WorkLogType, 'id' | 'ticketID' | 'fromDate' | 'toDate' | 'remark'> & {
        user?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            'id' | 'firstName' | 'lastName' | 'initials' | 'email' | 'mobile' | 'createDate' | 'lastLogin' | 'isActive'
          >
        >;
        workOptions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'WorkLogWorkOptionType' } & Pick<WorkLogWorkOptionType, 'workOptionID'> & {
                  workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                }
            >
          >
        >;
        parts?: Maybe<
          Array<
            Maybe<
              { __typename?: 'WorkLogSystemPartType' } & Pick<
                WorkLogSystemPartType,
                'workLogID' | 'systemPartID' | 'quantity'
              > & {
                  systemPart?: Maybe<
                    { __typename?: 'SystemPartType' } & Pick<SystemPartType, 'id' | 'systemID' | 'partID'> & {
                        part?: Maybe<
                          { __typename?: 'PartType' } & Pick<
                            PartType,
                            'id' | 'name' | 'serialNumber' | 'supplierID'
                          > & { supplier?: Maybe<{ __typename?: 'SupplierType' } & Pick<SupplierType, 'id' | 'name'>> }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type TemplateQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type TemplateQuery = { __typename?: 'Query' } & {
  template?: Maybe<{ __typename?: 'TemplateType' } & Pick<TemplateType, 'id' | 'name' | 'content'>>;
};

export type TemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type TemplatesQuery = { __typename?: 'Query' } & {
  templates?: Maybe<Array<Maybe<{ __typename?: 'TemplateType' } & Pick<TemplateType, 'id' | 'name' | 'content'>>>>;
};

export type TicketQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TicketQuery = { __typename?: 'Query' } & {
  ticket?: Maybe<
    { __typename?: 'TicketType' } & Pick<
      TicketType,
      | 'id'
      | 'offerID'
      | 'maintenanceContractID'
      | 'assigneeID'
      | 'createdDate'
      | 'dueDate'
      | 'executionDate'
      | 'priority'
      | 'status'
      | 'description'
      | 'remark'
      | 'source'
      | 'type'
      | 'totalHours'
    > & {
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<MaintenanceContractType, 'id'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                    customer?: Maybe<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'>>;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                    customer?: Maybe<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'>>;
                  }
              >;
            }
        >;
        assignee?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            'id' | 'firstName' | 'lastName' | 'initials' | 'email' | 'mobile' | 'lastLogin' | 'isActive'
          >
        >;
        defaultWorkOptions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TicketWorkOptionType' } & Pick<TicketWorkOptionType, 'ticketID' | 'workOptionID'> & {
                  workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                }
            >
          >
        >;
      }
  >;
};

export type TicketsQueryVariables = Exact<{
  userID?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<TicketStreamOrderBy>;
}>;

export type TicketsQuery = { __typename?: 'Query' } & {
  ticketStream?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TicketType' } & Pick<
          TicketType,
          | 'id'
          | 'offerID'
          | 'maintenanceContractID'
          | 'createdDate'
          | 'dueDate'
          | 'executionDate'
          | 'priority'
          | 'status'
          | 'description'
          | 'remark'
          | 'source'
          | 'totalHours'
          | 'type'
          | 'assigneeID'
        > & {
            offer?: Maybe<
              { __typename?: 'OfferType' } & Pick<OfferType, 'id'> & {
                  system?: Maybe<
                    { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                        address?: Maybe<
                          { __typename?: 'SystemAddressType' } & Pick<
                            SystemAddressType,
                            'id' | 'street' | 'zip' | 'city'
                          >
                        >;
                        maintainer?: Maybe<
                          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'initials' | 'firstName' | 'lastName'>
                        >;
                      }
                  >;
                }
            >;
            maintenanceContract?: Maybe<
              { __typename?: 'MaintenanceContractType' } & Pick<MaintenanceContractType, 'id'> & {
                  system?: Maybe<
                    { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'remark'> & {
                        address?: Maybe<
                          { __typename?: 'SystemAddressType' } & Pick<
                            SystemAddressType,
                            'id' | 'street' | 'zip' | 'city'
                          >
                        >;
                        maintainer?: Maybe<
                          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'initials' | 'firstName' | 'lastName'>
                        >;
                      }
                  >;
                }
            >;
            assignee?: Maybe<
              { __typename?: 'UserType' } & Pick<
                UserType,
                'id' | 'firstName' | 'lastName' | 'initials' | 'email' | 'mobile' | 'lastLogin' | 'isActive'
              >
            >;
          }
      >
    >
  >;
};

export type TranslationsQueryVariables = Exact<{ [key: string]: never }>;

export type TranslationsQuery = { __typename?: 'Query' } & {
  translations?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TranslationType' } & Pick<
          TranslationType,
          'id' | 'languageCode' | 'name' | 'page' | 'translatedValue'
        >
      >
    >
  >;
};

export type UpdateInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  status: Scalars['String'];
  modUser: Scalars['String'];
}>;

export type UpdateInvoiceMutation = { __typename?: 'Mutation' } & {
  updateInvoice?: Maybe<
    { __typename?: 'InvoiceType' } & Pick<
      InvoiceType,
      | 'id'
      | 'invoiceDate'
      | 'maintenanceContractID'
      | 'offerID'
      | 'status'
      | 'invoiceAmountExcl'
      | 'invoiceAmountIncl'
      | 'currencyCode'
      | 'type'
      | 'dueDate'
      | 'chargedUntil'
      | 'chargedFrom'
    > & {
        invoiceLines?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceLineType' } & Pick<
                InvoiceLineType,
                'id' | 'description' | 'lineAmountExcl' | 'quantity' | 'itemPrice'
              >
            >
          >
        >;
        maintenanceContract?: Maybe<
          { __typename?: 'MaintenanceContractType' } & Pick<
            MaintenanceContractType,
            'id' | 'totalPrice' | 'systemID'
          > & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
        offer?: Maybe<
          { __typename?: 'OfferType' } & Pick<OfferType, 'id' | 'systemID'> & {
              system?: Maybe<
                { __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code' | 'customerID'> & {
                    customer?: Maybe<
                      { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'> & {
                          address?: Maybe<
                            { __typename?: 'CustomerAddressType' } & Pick<
                              CustomerAddressType,
                              'id' | 'street' | 'zip' | 'city' | 'state' | 'country'
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type WorkLogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type WorkLogQuery = { __typename?: 'Query' } & {
  workLog?: Maybe<
    { __typename?: 'WorkLogType' } & Pick<
      WorkLogType,
      'id' | 'ticketID' | 'userID' | 'fromDate' | 'toDate' | 'remark'
    > & {
        user?: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            'id' | 'firstName' | 'lastName' | 'initials' | 'email' | 'mobile' | 'createDate' | 'lastLogin' | 'isActive'
          >
        >;
        workOptions?: Maybe<
          Array<
            Maybe<
              { __typename?: 'WorkLogWorkOptionType' } & Pick<WorkLogWorkOptionType, 'workOptionID'> & {
                  workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                }
            >
          >
        >;
        parts?: Maybe<
          Array<
            Maybe<
              { __typename?: 'WorkLogSystemPartType' } & Pick<
                WorkLogSystemPartType,
                'workLogID' | 'systemPartID' | 'quantity'
              > & {
                  systemPart?: Maybe<
                    { __typename?: 'SystemPartType' } & Pick<SystemPartType, 'id' | 'systemID' | 'partID'> & {
                        part?: Maybe<
                          { __typename?: 'PartType' } & Pick<
                            PartType,
                            'id' | 'name' | 'serialNumber' | 'supplierID'
                          > & { supplier?: Maybe<{ __typename?: 'SupplierType' } & Pick<SupplierType, 'id' | 'name'>> }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type WorkLogsForReportQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type WorkLogsForReportQuery = { __typename?: 'Query' } & {
  workLogsForReport?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkLogType' } & Pick<
          WorkLogType,
          'id' | 'ticketID' | 'userID' | 'fromDate' | 'toDate' | 'remark'
        > & {
            user?: Maybe<
              { __typename?: 'UserType' } & Pick<
                UserType,
                'id' | 'initials' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'lastLogin' | 'isActive'
              >
            >;
            ticket?: Maybe<
              { __typename?: 'TicketType' } & Pick<TicketType, 'id' | 'type' | 'description'> & {
                  maintenanceContract?: Maybe<
                    { __typename?: 'MaintenanceContractType' } & Pick<MaintenanceContractType, 'id'> & {
                        system?: Maybe<{ __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code'>>;
                      }
                  >;
                  offer?: Maybe<
                    { __typename?: 'OfferType' } & Pick<OfferType, 'id'> & {
                        system?: Maybe<{ __typename?: 'SystemType' } & Pick<SystemType, 'id' | 'code'>>;
                      }
                  >;
                }
            >;
            workOptions?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'WorkLogWorkOptionType' } & Pick<WorkLogWorkOptionType, 'workOptionID'> & {
                      workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type WorkLogsByTicketIdQueryVariables = Exact<{
  ticketID: Scalars['Int'];
}>;

export type WorkLogsByTicketIdQuery = { __typename?: 'Query' } & {
  workLogsByTicketID?: Maybe<
    Array<
      Maybe<
        { __typename?: 'WorkLogType' } & Pick<
          WorkLogType,
          'id' | 'ticketID' | 'userID' | 'fromDate' | 'toDate' | 'remark'
        > & {
            user?: Maybe<
              { __typename?: 'UserType' } & Pick<
                UserType,
                'id' | 'initials' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'lastLogin' | 'isActive'
              >
            >;
            workOptions?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'WorkLogWorkOptionType' } & Pick<WorkLogWorkOptionType, 'workOptionID'> & {
                      workOption?: Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type WorkOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkOptionsQuery = { __typename?: 'Query' } & {
  workOptions?: Maybe<Array<Maybe<{ __typename?: 'WorkOptionType' } & Pick<WorkOptionType, 'id' | 'name'>>>>;
};

export const ConfigurationsDocument = gql`
  query Configurations {
    configurations {
      id
      name
      value
      defaultValue
      type
      description
      options {
        id
        key
        label
        description
        sequence
      }
    }
  }
`;

/**
 * __useConfigurationsQuery__
 *
 * To run a query within a React component, call `useConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>
) {
  return Apollo.useQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
}
export function useConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>
) {
  return Apollo.useLazyQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
}
export type ConfigurationsQueryHookResult = ReturnType<typeof useConfigurationsQuery>;
export type ConfigurationsLazyQueryHookResult = ReturnType<typeof useConfigurationsLazyQuery>;
export type ConfigurationsQueryResult = Apollo.QueryResult<ConfigurationsQuery, ConfigurationsQueryVariables>;
export const CreateInvoiceDocument = gql`
  mutation CreateInvoice($invoiceInput: InvoiceInput!, $modUser: String!) {
    createInvoice(invoiceInput: $invoiceInput, modUser: $modUser) {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      modUser: // value for 'modUser'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>
) {
  return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;
export const ExcelReportDocument = gql`
  query ExcelReport($id: Int!) {
    excelReport(id: $id) {
      id
      name
      description
      lastViewing
      numberOfViews
      binaryData
    }
  }
`;

/**
 * __useExcelReportQuery__
 *
 * To run a query within a React component, call `useExcelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcelReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcelReportQuery(baseOptions: Apollo.QueryHookOptions<ExcelReportQuery, ExcelReportQueryVariables>) {
  return Apollo.useQuery<ExcelReportQuery, ExcelReportQueryVariables>(ExcelReportDocument, baseOptions);
}
export function useExcelReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExcelReportQuery, ExcelReportQueryVariables>
) {
  return Apollo.useLazyQuery<ExcelReportQuery, ExcelReportQueryVariables>(ExcelReportDocument, baseOptions);
}
export type ExcelReportQueryHookResult = ReturnType<typeof useExcelReportQuery>;
export type ExcelReportLazyQueryHookResult = ReturnType<typeof useExcelReportLazyQuery>;
export type ExcelReportQueryResult = Apollo.QueryResult<ExcelReportQuery, ExcelReportQueryVariables>;
export const InvoiceDocument = gql`
  query Invoice($id: Int!) {
    invoice(id: $id) {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
}
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoicesDocument = gql`
  query Invoices {
    invoices {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
}
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const OpenInvoiceDocument = gql`
  query OpenInvoice($maintenanceContractID: Int!) {
    openInvoice(maintenanceContractID: $maintenanceContractID) {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
          maintainer {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

/**
 * __useOpenInvoiceQuery__
 *
 * To run a query within a React component, call `useOpenInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenInvoiceQuery({
 *   variables: {
 *      maintenanceContractID: // value for 'maintenanceContractID'
 *   },
 * });
 */
export function useOpenInvoiceQuery(baseOptions: Apollo.QueryHookOptions<OpenInvoiceQuery, OpenInvoiceQueryVariables>) {
  return Apollo.useQuery<OpenInvoiceQuery, OpenInvoiceQueryVariables>(OpenInvoiceDocument, baseOptions);
}
export function useOpenInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpenInvoiceQuery, OpenInvoiceQueryVariables>
) {
  return Apollo.useLazyQuery<OpenInvoiceQuery, OpenInvoiceQueryVariables>(OpenInvoiceDocument, baseOptions);
}
export type OpenInvoiceQueryHookResult = ReturnType<typeof useOpenInvoiceQuery>;
export type OpenInvoiceLazyQueryHookResult = ReturnType<typeof useOpenInvoiceLazyQuery>;
export type OpenInvoiceQueryResult = Apollo.QueryResult<OpenInvoiceQuery, OpenInvoiceQueryVariables>;
export const OpenInvoicesDocument = gql`
  query OpenInvoices {
    openInvoices {
      id
      daysToPay
      yearlyPrice
      price
      customerName
      systemCode
    }
  }
`;

/**
 * __useOpenInvoicesQuery__
 *
 * To run a query within a React component, call `useOpenInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<OpenInvoicesQuery, OpenInvoicesQueryVariables>
) {
  return Apollo.useQuery<OpenInvoicesQuery, OpenInvoicesQueryVariables>(OpenInvoicesDocument, baseOptions);
}
export function useOpenInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpenInvoicesQuery, OpenInvoicesQueryVariables>
) {
  return Apollo.useLazyQuery<OpenInvoicesQuery, OpenInvoicesQueryVariables>(OpenInvoicesDocument, baseOptions);
}
export type OpenInvoicesQueryHookResult = ReturnType<typeof useOpenInvoicesQuery>;
export type OpenInvoicesLazyQueryHookResult = ReturnType<typeof useOpenInvoicesLazyQuery>;
export type OpenInvoicesQueryResult = Apollo.QueryResult<OpenInvoicesQuery, OpenInvoicesQueryVariables>;
export const OpenOfferInvoiceDocument = gql`
  query OpenOfferInvoice($offerID: Int!) {
    openOfferInvoice(offerID: $offerID) {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOpenOfferInvoiceQuery__
 *
 * To run a query within a React component, call `useOpenOfferInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenOfferInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenOfferInvoiceQuery({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useOpenOfferInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<OpenOfferInvoiceQuery, OpenOfferInvoiceQueryVariables>
) {
  return Apollo.useQuery<OpenOfferInvoiceQuery, OpenOfferInvoiceQueryVariables>(OpenOfferInvoiceDocument, baseOptions);
}
export function useOpenOfferInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpenOfferInvoiceQuery, OpenOfferInvoiceQueryVariables>
) {
  return Apollo.useLazyQuery<OpenOfferInvoiceQuery, OpenOfferInvoiceQueryVariables>(
    OpenOfferInvoiceDocument,
    baseOptions
  );
}
export type OpenOfferInvoiceQueryHookResult = ReturnType<typeof useOpenOfferInvoiceQuery>;
export type OpenOfferInvoiceLazyQueryHookResult = ReturnType<typeof useOpenOfferInvoiceLazyQuery>;
export type OpenOfferInvoiceQueryResult = Apollo.QueryResult<OpenOfferInvoiceQuery, OpenOfferInvoiceQueryVariables>;
export const OpenOfferInvoicesDocument = gql`
  query OpenOfferInvoices {
    openOfferInvoices {
      id
      remark
      hours
      parts
      customerName
      systemCode
    }
  }
`;

/**
 * __useOpenOfferInvoicesQuery__
 *
 * To run a query within a React component, call `useOpenOfferInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenOfferInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenOfferInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenOfferInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<OpenOfferInvoicesQuery, OpenOfferInvoicesQueryVariables>
) {
  return Apollo.useQuery<OpenOfferInvoicesQuery, OpenOfferInvoicesQueryVariables>(
    OpenOfferInvoicesDocument,
    baseOptions
  );
}
export function useOpenOfferInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpenOfferInvoicesQuery, OpenOfferInvoicesQueryVariables>
) {
  return Apollo.useLazyQuery<OpenOfferInvoicesQuery, OpenOfferInvoicesQueryVariables>(
    OpenOfferInvoicesDocument,
    baseOptions
  );
}
export type OpenOfferInvoicesQueryHookResult = ReturnType<typeof useOpenOfferInvoicesQuery>;
export type OpenOfferInvoicesLazyQueryHookResult = ReturnType<typeof useOpenOfferInvoicesLazyQuery>;
export type OpenOfferInvoicesQueryResult = Apollo.QueryResult<OpenOfferInvoicesQuery, OpenOfferInvoicesQueryVariables>;
export const ReportDocument = gql`
  query Report($id: Int!) {
    report(id: $id) {
      id
      name
      description
      lastViewing
      numberOfViews
      dataJSON
    }
  }
`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
  return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
}
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
  return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
}
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportsDocument = gql`
  query Reports {
    reports {
      id
      name
      description
      lastViewing
      numberOfViews
    }
  }
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
  return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
  return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const SaveConfigurationDocument = gql`
  mutation SaveConfiguration($id: Int!, $value: String, $modUser: String!) {
    saveConfiguration(id: $id, value: $value, modUser: $modUser) {
      id
      name
      value
      defaultValue
      type
      description
      options {
        id
        key
        label
        description
        sequence
      }
    }
  }
`;
export type SaveConfigurationMutationFn = Apollo.MutationFunction<
  SaveConfigurationMutation,
  SaveConfigurationMutationVariables
>;

/**
 * __useSaveConfigurationMutation__
 *
 * To run a mutation, you first call `useSaveConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConfigurationMutation, { data, loading, error }] = useSaveConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      modUser: // value for 'modUser'
 *   },
 * });
 */
export function useSaveConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveConfigurationMutation, SaveConfigurationMutationVariables>
) {
  return Apollo.useMutation<SaveConfigurationMutation, SaveConfigurationMutationVariables>(
    SaveConfigurationDocument,
    baseOptions
  );
}
export type SaveConfigurationMutationHookResult = ReturnType<typeof useSaveConfigurationMutation>;
export type SaveConfigurationMutationResult = Apollo.MutationResult<SaveConfigurationMutation>;
export type SaveConfigurationMutationOptions = Apollo.BaseMutationOptions<
  SaveConfigurationMutation,
  SaveConfigurationMutationVariables
>;
export const SaveTemplateDocument = gql`
  mutation SaveTemplate($templateInput: TemplateInput!) {
    saveTemplate(templateInput: $templateInput) {
      id
      name
      content
    }
  }
`;
export type SaveTemplateMutationFn = Apollo.MutationFunction<SaveTemplateMutation, SaveTemplateMutationVariables>;

/**
 * __useSaveTemplateMutation__
 *
 * To run a mutation, you first call `useSaveTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTemplateMutation, { data, loading, error }] = useSaveTemplateMutation({
 *   variables: {
 *      templateInput: // value for 'templateInput'
 *   },
 * });
 */
export function useSaveTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTemplateMutation, SaveTemplateMutationVariables>
) {
  return Apollo.useMutation<SaveTemplateMutation, SaveTemplateMutationVariables>(SaveTemplateDocument, baseOptions);
}
export type SaveTemplateMutationHookResult = ReturnType<typeof useSaveTemplateMutation>;
export type SaveTemplateMutationResult = Apollo.MutationResult<SaveTemplateMutation>;
export type SaveTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveTemplateMutation,
  SaveTemplateMutationVariables
>;
export const SaveTicketDocument = gql`
  mutation SaveTicket($ticketInput: TicketInput!, $modUser: String!) {
    saveTicket(ticketInput: $ticketInput, modUser: $modUser) {
      id
      offerID
      maintenanceContractID
      maintenanceContract {
        id
        system {
          id
          code
          remark
          customer {
            id
            name
          }
        }
      }
      offer {
        id
        system {
          id
          code
          remark
          customer {
            id
            name
          }
        }
      }
      createdDate
      dueDate
      executionDate
      priority
      status
      remark
      description
      source
      type
      assigneeID
      assignee {
        id
        firstName
        lastName
        initials
        email
        mobile
        ahvNumber
        workload
        annualGrossSalary
        eligibleForLostHours
        hasDecisionMakingPosition
        createDate
        lastLogin
        isActive
      }
      defaultWorkOptions {
        ticketID
        workOptionID
        workOption {
          id
          name
        }
      }
      totalHours
    }
  }
`;
export type SaveTicketMutationFn = Apollo.MutationFunction<SaveTicketMutation, SaveTicketMutationVariables>;

/**
 * __useSaveTicketMutation__
 *
 * To run a mutation, you first call `useSaveTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTicketMutation, { data, loading, error }] = useSaveTicketMutation({
 *   variables: {
 *      ticketInput: // value for 'ticketInput'
 *      modUser: // value for 'modUser'
 *   },
 * });
 */
export function useSaveTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTicketMutation, SaveTicketMutationVariables>
) {
  return Apollo.useMutation<SaveTicketMutation, SaveTicketMutationVariables>(SaveTicketDocument, baseOptions);
}
export type SaveTicketMutationHookResult = ReturnType<typeof useSaveTicketMutation>;
export type SaveTicketMutationResult = Apollo.MutationResult<SaveTicketMutation>;
export type SaveTicketMutationOptions = Apollo.BaseMutationOptions<SaveTicketMutation, SaveTicketMutationVariables>;
export const SaveTranslationDocument = gql`
  mutation SaveTranslation($translationInput: TranslationInput!, $modUser: String!) {
    saveTranslation(translationInput: $translationInput, modUser: $modUser) {
      id
      languageCode
      name
      page
      translatedValue
    }
  }
`;
export type SaveTranslationMutationFn = Apollo.MutationFunction<
  SaveTranslationMutation,
  SaveTranslationMutationVariables
>;

/**
 * __useSaveTranslationMutation__
 *
 * To run a mutation, you first call `useSaveTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTranslationMutation, { data, loading, error }] = useSaveTranslationMutation({
 *   variables: {
 *      translationInput: // value for 'translationInput'
 *      modUser: // value for 'modUser'
 *   },
 * });
 */
export function useSaveTranslationMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTranslationMutation, SaveTranslationMutationVariables>
) {
  return Apollo.useMutation<SaveTranslationMutation, SaveTranslationMutationVariables>(
    SaveTranslationDocument,
    baseOptions
  );
}
export type SaveTranslationMutationHookResult = ReturnType<typeof useSaveTranslationMutation>;
export type SaveTranslationMutationResult = Apollo.MutationResult<SaveTranslationMutation>;
export type SaveTranslationMutationOptions = Apollo.BaseMutationOptions<
  SaveTranslationMutation,
  SaveTranslationMutationVariables
>;
export const SaveWorkLogDocument = gql`
  mutation SaveWorkLog($workLogInput: WorkLogInput!) {
    saveWorkLog(workLogInput: $workLogInput) {
      id
      ticketID
      user {
        id
        firstName
        lastName
        initials
        email
        mobile
        createDate
        lastLogin
        isActive
      }
      fromDate
      toDate
      remark
      workOptions {
        workOptionID
        workOption {
          id
          name
        }
      }
      parts {
        workLogID
        systemPartID
        systemPart {
          id
          systemID
          partID
          part {
            id
            name
            serialNumber
            supplierID
            supplier {
              id
              name
            }
          }
        }
        quantity
      }
    }
  }
`;
export type SaveWorkLogMutationFn = Apollo.MutationFunction<SaveWorkLogMutation, SaveWorkLogMutationVariables>;

/**
 * __useSaveWorkLogMutation__
 *
 * To run a mutation, you first call `useSaveWorkLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkLogMutation, { data, loading, error }] = useSaveWorkLogMutation({
 *   variables: {
 *      workLogInput: // value for 'workLogInput'
 *   },
 * });
 */
export function useSaveWorkLogMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveWorkLogMutation, SaveWorkLogMutationVariables>
) {
  return Apollo.useMutation<SaveWorkLogMutation, SaveWorkLogMutationVariables>(SaveWorkLogDocument, baseOptions);
}
export type SaveWorkLogMutationHookResult = ReturnType<typeof useSaveWorkLogMutation>;
export type SaveWorkLogMutationResult = Apollo.MutationResult<SaveWorkLogMutation>;
export type SaveWorkLogMutationOptions = Apollo.BaseMutationOptions<SaveWorkLogMutation, SaveWorkLogMutationVariables>;
export const TemplateDocument = gql`
  query Template($name: String!) {
    template(name: $name) {
      id
      name
      content
    }
  }
`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
  return Apollo.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
}
export function useTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
  return Apollo.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
}
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateQueryResult = Apollo.QueryResult<TemplateQuery, TemplateQueryVariables>;
export const TemplatesDocument = gql`
  query Templates {
    templates {
      id
      name
      content
    }
  }
`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
  return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
}
export function useTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>
) {
  return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
}
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const TicketDocument = gql`
  query ticket($id: Int!) {
    ticket(id: $id) {
      id
      offerID
      maintenanceContractID
      maintenanceContract {
        id
        system {
          id
          code
          remark
          customer {
            id
            name
          }
        }
      }
      offer {
        id
        system {
          id
          code
          remark
          customer {
            id
            name
          }
        }
      }
      assigneeID
      assignee {
        id
        firstName
        lastName
        initials
        email
        mobile
        lastLogin
        isActive
      }
      createdDate
      dueDate
      executionDate
      priority
      status
      description
      remark
      source
      type
      defaultWorkOptions {
        ticketID
        workOptionID
        workOption {
          id
          name
        }
      }
      totalHours
    }
  }
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables>) {
  return Apollo.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions);
}
export function useTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
  return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions);
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>;
export const TicketsDocument = gql`
  query Tickets($userID: Int, $text: String, $orderBy: TicketStreamOrderBy) {
    ticketStream(userID: $userID, text: $text, orderBy: $orderBy) {
      id
      offerID
      maintenanceContractID
      offer {
        id
        system {
          id
          code
          remark
          address {
            id
            street
            zip
            city
          }
          maintainer {
            id
            initials
            firstName
            lastName
          }
        }
      }
      maintenanceContract {
        id
        system {
          id
          code
          remark
          address {
            id
            street
            zip
            city
          }
          maintainer {
            id
            initials
            firstName
            lastName
          }
        }
      }
      createdDate
      dueDate
      executionDate
      priority
      status
      description
      remark
      source
      totalHours
      type
      assigneeID
      assignee {
        id
        firstName
        lastName
        initials
        email
        mobile
        lastLogin
        isActive
      }
    }
  }
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      text: // value for 'text'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTicketsQuery(baseOptions?: Apollo.QueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  return Apollo.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
}
export function useTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  return Apollo.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<TicketsQuery, TicketsQueryVariables>;
export const TranslationsDocument = gql`
  query Translations {
    translations {
      id
      languageCode
      name
      page
      translatedValue
    }
  }
`;

/**
 * __useTranslationsQuery__
 *
 * To run a query within a React component, call `useTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTranslationsQuery(
  baseOptions?: Apollo.QueryHookOptions<TranslationsQuery, TranslationsQueryVariables>
) {
  return Apollo.useQuery<TranslationsQuery, TranslationsQueryVariables>(TranslationsDocument, baseOptions);
}
export function useTranslationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TranslationsQuery, TranslationsQueryVariables>
) {
  return Apollo.useLazyQuery<TranslationsQuery, TranslationsQueryVariables>(TranslationsDocument, baseOptions);
}
export type TranslationsQueryHookResult = ReturnType<typeof useTranslationsQuery>;
export type TranslationsLazyQueryHookResult = ReturnType<typeof useTranslationsLazyQuery>;
export type TranslationsQueryResult = Apollo.QueryResult<TranslationsQuery, TranslationsQueryVariables>;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($invoiceId: Int!, $status: String!, $modUser: String!) {
    updateInvoice(invoiceId: $invoiceId, status: $status, modUser: $modUser) {
      id
      invoiceDate
      maintenanceContractID
      offerID
      status
      invoiceAmountExcl
      invoiceAmountIncl
      currencyCode
      type
      dueDate
      chargedUntil
      chargedFrom
      invoiceLines {
        id
        description
        lineAmountExcl
        quantity
        itemPrice
      }
      maintenanceContract {
        id
        totalPrice
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
      offer {
        id
        systemID
        system {
          id
          code
          customerID
          customer {
            id
            name
            address {
              id
              street
              zip
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      status: // value for 'status'
 *      modUser: // value for 'modUser'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
) {
  return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
}
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const WorkLogDocument = gql`
  query workLog($id: Int!) {
    workLog(id: $id) {
      id
      ticketID
      userID
      user {
        id
        firstName
        lastName
        initials
        email
        mobile
        createDate
        lastLogin
        isActive
      }
      fromDate
      toDate
      remark
      workOptions {
        workOptionID
        workOption {
          id
          name
        }
      }
      parts {
        workLogID
        systemPartID
        systemPart {
          id
          systemID
          partID
          part {
            id
            name
            serialNumber
            supplierID
            supplier {
              id
              name
            }
          }
        }
        quantity
      }
    }
  }
`;

/**
 * __useWorkLogQuery__
 *
 * To run a query within a React component, call `useWorkLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkLogQuery(baseOptions: Apollo.QueryHookOptions<WorkLogQuery, WorkLogQueryVariables>) {
  return Apollo.useQuery<WorkLogQuery, WorkLogQueryVariables>(WorkLogDocument, baseOptions);
}
export function useWorkLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkLogQuery, WorkLogQueryVariables>) {
  return Apollo.useLazyQuery<WorkLogQuery, WorkLogQueryVariables>(WorkLogDocument, baseOptions);
}
export type WorkLogQueryHookResult = ReturnType<typeof useWorkLogQuery>;
export type WorkLogLazyQueryHookResult = ReturnType<typeof useWorkLogLazyQuery>;
export type WorkLogQueryResult = Apollo.QueryResult<WorkLogQuery, WorkLogQueryVariables>;
export const WorkLogsForReportDocument = gql`
  query workLogsForReport($from: Date!, $to: Date!) {
    workLogsForReport(from: $from, to: $to) {
      id
      ticketID
      userID
      user {
        id
        initials
        firstName
        lastName
        email
        mobile
        lastLogin
        isActive
      }
      ticket {
        id
        type
        description
        maintenanceContract {
          id
          system {
            id
            code
          }
        }
        offer {
          id
          system {
            id
            code
          }
        }
      }
      fromDate
      toDate
      remark
      workOptions {
        workOptionID
        workOption {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useWorkLogsForReportQuery__
 *
 * To run a query within a React component, call `useWorkLogsForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkLogsForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkLogsForReportQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useWorkLogsForReportQuery(
  baseOptions: Apollo.QueryHookOptions<WorkLogsForReportQuery, WorkLogsForReportQueryVariables>
) {
  return Apollo.useQuery<WorkLogsForReportQuery, WorkLogsForReportQueryVariables>(
    WorkLogsForReportDocument,
    baseOptions
  );
}
export function useWorkLogsForReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkLogsForReportQuery, WorkLogsForReportQueryVariables>
) {
  return Apollo.useLazyQuery<WorkLogsForReportQuery, WorkLogsForReportQueryVariables>(
    WorkLogsForReportDocument,
    baseOptions
  );
}
export type WorkLogsForReportQueryHookResult = ReturnType<typeof useWorkLogsForReportQuery>;
export type WorkLogsForReportLazyQueryHookResult = ReturnType<typeof useWorkLogsForReportLazyQuery>;
export type WorkLogsForReportQueryResult = Apollo.QueryResult<WorkLogsForReportQuery, WorkLogsForReportQueryVariables>;
export const WorkLogsByTicketIdDocument = gql`
  query workLogsByTicketID($ticketID: Int!) {
    workLogsByTicketID(ticketID: $ticketID) {
      id
      ticketID
      userID
      user {
        id
        initials
        firstName
        lastName
        email
        mobile
        lastLogin
        isActive
      }
      fromDate
      toDate
      remark
      workOptions {
        workOptionID
        workOption {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useWorkLogsByTicketIdQuery__
 *
 * To run a query within a React component, call `useWorkLogsByTicketIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkLogsByTicketIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkLogsByTicketIdQuery({
 *   variables: {
 *      ticketID: // value for 'ticketID'
 *   },
 * });
 */
export function useWorkLogsByTicketIdQuery(
  baseOptions: Apollo.QueryHookOptions<WorkLogsByTicketIdQuery, WorkLogsByTicketIdQueryVariables>
) {
  return Apollo.useQuery<WorkLogsByTicketIdQuery, WorkLogsByTicketIdQueryVariables>(
    WorkLogsByTicketIdDocument,
    baseOptions
  );
}
export function useWorkLogsByTicketIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkLogsByTicketIdQuery, WorkLogsByTicketIdQueryVariables>
) {
  return Apollo.useLazyQuery<WorkLogsByTicketIdQuery, WorkLogsByTicketIdQueryVariables>(
    WorkLogsByTicketIdDocument,
    baseOptions
  );
}
export type WorkLogsByTicketIdQueryHookResult = ReturnType<typeof useWorkLogsByTicketIdQuery>;
export type WorkLogsByTicketIdLazyQueryHookResult = ReturnType<typeof useWorkLogsByTicketIdLazyQuery>;
export type WorkLogsByTicketIdQueryResult = Apollo.QueryResult<
  WorkLogsByTicketIdQuery,
  WorkLogsByTicketIdQueryVariables
>;
export const WorkOptionsDocument = gql`
  query workOptions {
    workOptions {
      id
      name
    }
  }
`;

/**
 * __useWorkOptionsQuery__
 *
 * To run a query within a React component, call `useWorkOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<WorkOptionsQuery, WorkOptionsQueryVariables>
) {
  return Apollo.useQuery<WorkOptionsQuery, WorkOptionsQueryVariables>(WorkOptionsDocument, baseOptions);
}
export function useWorkOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkOptionsQuery, WorkOptionsQueryVariables>
) {
  return Apollo.useLazyQuery<WorkOptionsQuery, WorkOptionsQueryVariables>(WorkOptionsDocument, baseOptions);
}
export type WorkOptionsQueryHookResult = ReturnType<typeof useWorkOptionsQuery>;
export type WorkOptionsLazyQueryHookResult = ReturnType<typeof useWorkOptionsLazyQuery>;
export type WorkOptionsQueryResult = Apollo.QueryResult<WorkOptionsQuery, WorkOptionsQueryVariables>;
