/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { PATH_CONFIRM_INVITATION, PATH_CONFIRM_REGISTRATION } from './constants';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/external-login',
    component: lazy(() => import('src/views/auth/ExternalLoginView'))
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: PATH_CONFIRM_REGISTRATION,
    component: lazy(() => import('src/views/auth/ConfirmRegistrationView'))
  },
  {
    exact: true,
    path: PATH_CONFIRM_INVITATION,
    component: lazy(() => import('src/views/auth/ConfirmRegistrationView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register-info',
    component: lazy(() => import('src/views/auth/RegisterInfoView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard-internal" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/account/create',
        component: lazy(() => import('src/views/pages/UserCreateView'))
      },
      {
        exact: true,
        path: '/app/cms',
        component: lazy(() => import('src/views/pages/CMSView'))
      },
      {
        exact: true,
        path: '/app/systemParameters',
        component: lazy(() => import('src/views/pages/SystemParametersView'))
      },
      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('src/views/pages/SettingsView'))
      },
      {
        exact: true,
        path: '/app/templates',
        component: lazy(() => import('src/views/pages/TemplateView'))
      },
      {
        exact: true,
        path: '/app/accounts',
        component: lazy(() => import('src/views/pages/UserListView'))
      },
      {
        exact: true,
        path: '/app/accounts/:editUserId',
        component: lazy(() => import('src/views/pages/UserEditView'))
      },
      {
        exact: true,
        path: '/app/company',
        component: lazy(() => import('src/views/company/DetailsView'))
      },
      {
        exact: true,
        path: '/app/management/offers',
        component: lazy(() => import('src/views/management/OfferListView'))
      },
      {
        exact: true,
        path: '/app/management/offers/create',
        component: lazy(() => import('src/views/management/OffersCreateView'))
      },
      {
        exact: true,
        path: '/app/management/offers/:offerId',
        component: lazy(() => import('src/views/management/OffersEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/create',
        component: lazy(() => import('src/views/management/InvoiceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/open-invoices',
        component: lazy(() => import('src/views/management/OpenInvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/contracts',
        component: lazy(() => import('src/views/management/ContractListView'))
      },
      {
        exact: true,
        path: '/app/management/contracts/create',
        component: lazy(() => import('src/views/management/ContractCreateView'))
      },
      {
        exact: true,
        path: '/app/management/contracts/:contractId',
        component: lazy(() => import('src/views/management/ContractEditView'))
      },
      {
        exact: true,
        path: '/app/management/reports/work',
        component: lazy(() => import('src/views/management/WorkLogReport'))
      },

      {
        exact: true,
        path: '/app/accounts/:editUserId',
        component: lazy(() => import('src/views/pages/UserEditView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-internal',
        component: lazy(() => import('src/views/reports/DashboardInternalView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/tasks/tickets',
        component: lazy(() => import('src/views/tasks/tickets/TicketListView'))
      },
      {
        exact: true,
        path: '/app/tasks/my-tickets',
        component: lazy(() => import('src/views/tasks/tickets/TicketInboxView'))
      },
      {
        exact: true,
        path: '/app/tasks/tickets/create',
        component: lazy(() => import('src/views/tasks/tickets/TicketEditView'))
      },
      {
        exact: true,
        path: '/app/tasks/tickets/edit/:id',
        component: lazy(() => import('src/views/tasks/tickets/TicketEditView'))
      },
      {
        exact: true,
        path: '/app/tasks/worklogs/edit/:ticketId/:workLogId',
        component: lazy(() => import('src/views/tasks/workLogs/WorklogEditView'))
      },
      {
        exact: true,
        path: '/app/management/reports/generic',
        component: lazy(() => import('src/views/management/GenericReportListView'))
      },
      {
        exact: true,
        path: '/app/management/reports/generic/:reportId',
        component: lazy(() => import('src/views/management/GenericReportDetailView'))
      },
      {
        exact: true,
        path: '/app/management/systems',
        component: lazy(() => import('src/views/management/SystemListView'))
      },
      {
        exact: true,
        path: '/app/management/systems/create',
        component: lazy(() => import('src/views/management/SystemEditView'))
      },
      {
        exact: true,
        path: '/app/management/systems/edit/:id',
        component: lazy(() => import('src/views/management/SystemEditView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    exact: true,
    path: '/:tenantCode',
    component: lazy(() => import('src/views/auth/LoginView'))
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
