/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CONFIGURATIONS,
  GET_TEMPLATE,
  GET_TEMPLATES,
  GET_TRANSLATIONS,
  SAVE_TEMPLATE,
  SAVE_CONFIGURATION,
  SAVE_TRANSLATION
} from 'src/actions/cmsActions';
import { TranslationType, TemplateType } from 'src/generated/graphql';
import { CmsState } from 'src/store/storeInterface';

const initialState: CmsState = {
  translations: [],
  templates: [],
  configurations: [],
  template: {} as TemplateType
};

const cmsReducer = (state = initialState, action: { type: string; payload: any }) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_TRANSLATIONS: {
      const { translations } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.translations = translations;
      });
    }
    case SAVE_TRANSLATION: {
      const { saveTranslation }: { saveTranslation: TranslationType } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.translations = draft.translations.map(t => (t.id === saveTranslation.id ? saveTranslation : t));
      });
    }
    case GET_TEMPLATES: {
      const { templates } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.templates = templates;
      });
    }
    case GET_TEMPLATE: {
      const { template } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.template = template;
      });
    }
    case SAVE_TEMPLATE: {
      const { template } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.template = template;
      });
    }
    case GET_CONFIGURATIONS: {
      const { configurations } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.configurations = configurations;
      });
    }
    case SAVE_CONFIGURATION: {
      const { configurations } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.configurations = configurations;
      });
    }
    default: {
      return state;
    }
  }
};

export default cmsReducer;
