/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_OFFERS,
  GET_OFFER,
  GET_SERVICES,
  SAVE_OFFER,
  CHANGE_OFFER_STATUS,
  ADD_REDIRECT_TO_LIST,
  CLEAR_REDIRECT_TO_LIST
} from 'src/actions/offerActions';

const initialState = {
  offers: null
};

const offerReducer = (state = initialState, action) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_OFFERS: {
      const { offers } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.offers = offers;
      });
    }
    case SAVE_OFFER: {
      const { saveOffer } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.offer = saveOffer;
      });
    }
    case GET_OFFER: {
      const { offer } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.offer = offer;
      });
    }
    case GET_SERVICES: {
      const { services } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.services = services;
      });
    }
    case CHANGE_OFFER_STATUS: {
      const { changeOffer } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        if (draft.offer) {
          draft.offer.createdDate = changeOffer.createdDate;
          draft.offer.endDate = changeOffer.endDate;
          draft.offer.signedDate = changeOffer.signedDate;
          draft.offer.status = changeOffer.status;
        }
        if (state.offers && state.offers.length > 0) {
          var index = state.offers.findIndex(c => c.id === changeOffer.id);
          if (index >= 0) {
            draft.offers[index].createdDate = changeOffer.createdDate;
            draft.offers[index].endDate = changeOffer.endDate;
            draft.offers[index].signedDate = changeOffer.signedDate;
            draft.offers[index].status = changeOffer.status;
          }
        }
      });
    }

    case ADD_REDIRECT_TO_LIST: {
      const { message } = action.payload;
      return produce(state, draft => {
        draft.redirectToList = true;
        draft.redirectMessage = message;
      });
    }

    case CLEAR_REDIRECT_TO_LIST: {
      return produce(state, draft => {
        draft.redirectToList = false;
        draft.redirectMessage = {};
      });
    }

    default: {
      return state;
    }
  }
};

export default offerReducer;
