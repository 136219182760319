/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CONTRACT,
  GET_CONTRACTS,
  CHANGE_CONTRACT_STATUS,
  COPY_CONTRACT,
  SAVE_CONTRACT,
  CREATE_CONTRACT,
  NEW_CONTRACT_HANDLED,
  CLEAR_REDIRECT_TO_LIST,
  ADD_REDIRECT_TO_LIST
} from 'src/actions/contractActions';

const initialState = {
  contracts: null
};

const contractReducer = (state = initialState, action) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_CONTRACTS: {
      const { maintenanceContracts } = action.payload;
      return produce(state, draft => {
        draft.contracts = maintenanceContracts;
      });
    }

    case CHANGE_CONTRACT_STATUS: {
      const { changeMaintenanceContract } = action.payload;
      return produce(state, draft => {
        if (draft.contract) {
          draft.contract.createdDate = changeMaintenanceContract.createdDate;
          draft.contract.endDate = changeMaintenanceContract.endDate;
          draft.contract.signedDate = changeMaintenanceContract.signedDate;
          draft.contract.signatoryID = changeMaintenanceContract.signatoryID;
          draft.contract.status = changeMaintenanceContract.status;
          draft.contract.internalRemark = changeMaintenanceContract.internalRemark;
        }
        if (state.contracts && state.contracts.length > 0) {
          var index = state.contracts.findIndex(c => c.id === changeMaintenanceContract.id);
          if (index >= 0) {
            draft.contracts[index].createdDate = changeMaintenanceContract.createdDate;
            draft.contracts[index].endDate = changeMaintenanceContract.endDate;
            draft.contracts[index].signedDate = changeMaintenanceContract.signedDate;
            draft.contracts[index].signatoryID = changeMaintenanceContract.signatoryID;
            draft.contracts[index].status = changeMaintenanceContract.status;
            draft.contracts[index].internalRemark = changeMaintenanceContract.internalRemark;
          }
        }
      });
    }

    case COPY_CONTRACT: {
      const { copyMaintenanceContract } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.contracts.push(copyMaintenanceContract);
        draft.newContractId = copyMaintenanceContract.id;
      });
    }

    case NEW_CONTRACT_HANDLED: {
      return produce(state, draft => {
        draft.newContractId = undefined;
      });
    }

    case GET_CONTRACT: {
      const { maintenanceContract } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.contract = maintenanceContract;
      });
    }

    case SAVE_CONTRACT: {
      const { saveMaintenanceContract } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.contract = saveMaintenanceContract;
        if (state.contracts && state.contracts.length > 0) {
          var index = state.contracts.findIndex(c => c.id === saveMaintenanceContract.id);
          draft.contracts[index] = saveMaintenanceContract;
        }
      });
    }

    case CREATE_CONTRACT: {
      const { contract } = action.payload;
      console.log('Create contract reducer');
      console.log(contract);
      return produce(state, draft => {
        draft.contract = contract;
      });
    }

    case ADD_REDIRECT_TO_LIST: {
      const { message } = action.payload;
      return produce(state, draft => {
        draft.redirectToList = true;
        draft.redirectMessage = message;
      });
    }

    case CLEAR_REDIRECT_TO_LIST: {
      return produce(state, draft => {
        draft.redirectToList = false;
        draft.redirectMessage = undefined;
      });
    }

    default: {
      return state;
    }
  }
};

export default contractReducer;
