import { gql } from 'apollo-boost';

const fieldsOnOffer = gql`
  fragment fieldsOnOffer on OfferType {
    id
    systemID
    system {
      id
      code
      owner {
        id
        name
        address {
          id
          street
          zip
          city
        }
      }
      administration {
        id
        name
        address {
          id
          street
          zip
          city
        }
      }
      customer {
        id
        name
        address {
          id
          street
          zip
          city
        }
      }
      address {
        id
        street
        zip
        city
      }
    }
    creatorID
    creator {
      id
      firstName
      lastName
      email
      initials
    }
    createdDate
    signedDate
    deliveryDate
    validityDate
    totalPrice
    currencyCode
    status
    remark
    parts {
      systemPart {
        id
        part {
          id
          name
          serialNumber
          supplier {
            id
            name
          }
        }
        systemID
      }
      systemPartID
      quantity
      offeredPrice
      currencyCode
    }
    services {
      service {
        id
        name
        serviceCategory {
          name
        }
      }
      serviceID
      offeredPrice
      currencyCode
      hours
    }
  }
`;

export const GET_OFFERS_QUERY = gql`
  query($text: String) {
    offers(text: $text) {
      id
      system {
        id
        code
        customer {
          id
          name
        }
        address {
          id
          street
        }
      }
      createdDate
      status
      remark
    }
  }
`;

export const GET_OFFER_QUERY = gql`
  query($id: Int!) {
    offer(id: $id) {
      ...fieldsOnOffer
    }
  }
  ${fieldsOnOffer}
`;

export const SAVE_OFFER_MUTATION = gql`
  mutation($offerInput: OfferInput!, $modUser: String!) {
    saveOffer(offerInput: $offerInput, modUser: $modUser) {
      ...fieldsOnOffer
    }
  }
  ${fieldsOnOffer}
`;

export const GET_SERVICES_QUERY = gql`
  query {
    services {
      id
      name
      price
      currencyCode
      serviceCategory {
        name
      }
    }
  }
`;

export const CHANGE_OFFER_STATUS_MUTATION = gql`
  mutation ChangeOffer($id: Int!, $status: OfferStatus!, $date: DateTime!, $modUser: String!) {
    changeOffer(id: $id, status: $status, date: $date, modUser: $modUser) {
      ...fieldsOnOffer
    }
  }
  ${fieldsOnOffer}
`;
