/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SystemParameterUploadPreview } from 'src/views/pages/SystemParametersView/interfaces/cmsModuleInterfaces';
import { UPLOAD_SYSTEM_PARAM_PREVIEW } from 'src/actions/fileActions';
import { FileState } from 'src/store/storeInterface';

const initialState: FileState = {
  uploadPreview: {} as SystemParameterUploadPreview
};

const fileReducer = (state = initialState, action: { type: string; payload: any }) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case UPLOAD_SYSTEM_PARAM_PREVIEW: {
      const { uploadPreview } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.uploadPreview = uploadPreview;
        //  draft.configurations = configurations;
      });
    }
    default: {
      return state;
    }
  }
};

export default fileReducer;
