import axios from 'src/utils/axios';

class TranslateService {
  load = async (i18n, tenantCode) => {
    await axios
      .get(process.env.REACT_APP_TRANSLATE_URL + `?tenantCode=${tenantCode}`)
      .then(response => {
        if (response.statusText === 'OK') {
          response.data.forEach(item => {
            let obj = {};
            item.translations.forEach(trans => {
              obj[trans.name + (trans.page ? ':' + trans.page : '')] = trans.translatedValue;
            });
            i18n.addResourceBundle(item.languageCode, 'translations', obj);
          });
        }
      })
      .catch(error => {
        console.warn('i18n error:', error);
      });
  };

  update = async (i18n, tenantId) => {
    await axios
      .get(process.env.REACT_APP_TRANSLATE_URL + `?tenantId=${tenantId}`)
      .then(response => {
        if (response.statusText === 'OK') {
          response.data.forEach(item => {
            let obj = {};
            item.translations.forEach(trans => {
              obj[trans.name + (trans.page ? ':' + trans.page : '')] = trans.translatedValue;
            });
            i18n.addResourceBundle(item.languageCode, 'translations', obj);
          });
        }
      })
      .catch(error => {
        console.warn('i18n error:', error);
      });
  };
}

const translateService = new TranslateService();

export default translateService;
