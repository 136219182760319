/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_REPORTS, GET_REPORT } from 'src/actions/reportActions';
import { ReportType } from 'src/generated/graphql';
import { ReportState } from 'src/store/storeInterface';

const initialState: ReportState = {
  reports: [],
  report: {} as ReportType
};

const cmsReducer = (state = initialState, action: { type: string; payload: any }) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_REPORTS: {
      const { reports } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.reports = reports;
      });
    }
    case GET_REPORT: {
      const { report } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.report = report;
      });
    }
    default: {
      return state;
    }
  }
};

export default cmsReducer;
