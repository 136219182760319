/* eslint-disable no-param-reassign */
import { TicketType, WorkLogType } from 'src/generated/graphql';
import { TicketState, MessageType } from 'src/store/storeInterface';
import produce from 'immer';
import {
  GET_TICKET,
  GET_TICKETS,
  GET_WORKOPTIONS,
  GET_WORKLOG,
  GET_WORKLOGS_FOR_TICKET,
  GET_WORKLOGS_FOR_REPORT,
  SAVE_WORKLOG,
  SAVE_TICKET,
  CLEAR_REDIRECT_TO_LIST,
  ADD_REDIRECT_TO_LIST,
  CLEAR_WORKLOGS_FOR_REPORT
} from 'src/actions/ticketActionsTS';

const initialState: TicketState = {
  tickets: [],
  ticket: {} as TicketType,
  redirectToList: false,
  redirectMessage: {} as MessageType,
  workOptions: [],
  workLogsForReport: [],
  workLogsByTicket: [],
  workLog: {} as WorkLogType
};

const contractReducer = (state = initialState, action: { type: string; payload: any }) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_TICKETS: {
      const { tickets }: { tickets: TicketType[] } = action.payload;
      return produce(state, draft => {
        draft.tickets = tickets;
      });
    }

    case GET_TICKET: {
      const { ticket } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.ticket = ticket;
      });
    }

    case GET_WORKOPTIONS: {
      const { workOptions } = action.payload;
      return produce(state, draft => {
        draft.workOptions = workOptions;
      });
    }
    case SAVE_TICKET: {
      const { saveTicket } = action.payload;
      console.log('in dispatch SAVE_TICKET');
      return produce(state, draft => {
        draft.ticket = saveTicket;
        if (state.tickets && state.tickets.length > 0) {
          var index = state.tickets.findIndex(c => c.id === saveTicket.id);
          if (index >= 0) {
            draft.tickets[index] = saveTicket;
          } else {
            draft.tickets = [...draft.tickets, saveTicket];
          }
        }
      });
    }

    case SAVE_WORKLOG: {
      const { saveWorkLog } = action.payload;
      return produce(state, draft => {
        draft.workLog = saveWorkLog;
        if (draft.workLogsByTicket !== undefined) {
          var index = draft.workLogsByTicket.findIndex(w => w.id === saveWorkLog.id);
          if (index >= 0) {
            draft.workLogsByTicket[index] = saveWorkLog;
          } else {
            draft.workLogsByTicket.push(saveWorkLog);
          }
        }
      });
    }

    case GET_WORKLOGS_FOR_REPORT: {
      const { workLogsForReport } = action.payload;
      return produce(state, draft => {
        draft.workLogsForReport = workLogsForReport;
      });
    }

    case GET_WORKLOGS_FOR_TICKET: {
      const { workLogsByTicketID } = action.payload;
      return produce(state, draft => {
        draft.workLogsByTicket = workLogsByTicketID;
      });
    }

    case GET_WORKLOG: {
      const { workLog } = action.payload;
      return produce(state, draft => {
        draft.workLog = workLog;
      });
    }

    case ADD_REDIRECT_TO_LIST: {
      const { message } = action.payload;
      return produce(state, draft => {
        draft.redirectToList = true;
        draft.redirectMessage = message;
      });
    }

    case CLEAR_REDIRECT_TO_LIST: {
      return produce(state, draft => {
        draft.redirectToList = false;
        draft.redirectMessage = {} as MessageType;
      });
    }

    case CLEAR_WORKLOGS_FOR_REPORT: {
      return produce(state, draft => {
        draft.workLogsForReport = [];
      });
    }

    default: {
      return state;
    }
  }
};

export default contractReducer;
