import { gql } from 'apollo-boost';
import authService from 'src/services/authService';
import client from 'src/utils/apolloClient';
import { PATH_CONFIRM_INVITATION } from 'src/constants';
import { ADD_QUE_MESSAGE, handleResponseError } from '../actions/errorActions';
import i18n from 'src/i18n';
import translateService from 'src/services/translateService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const LOGIN_EXTERNAL_REQUEST = '@account/login-external-request';
export const LOGIN_EXTERNAL_SUCCESS = '@account/login-external-success';
export const LOGIN_EXTERNAL_FAILURE = '@account/login-external-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER_REQUEST = '@account/register-request';
export const REGISTER_SUCCESS = '@account/register-success';
export const REGISTER_FAILURE = '@account/register-failure';
export const CONFIRM_REGISTRATION_REQUEST = '@account/registration-request';
export const CONFIRM_REGISTRATION_SUCCESS = '@account/registration-success';
export const CONFIRM_REGISTRATION_FAILURE = '@account/registration-failure';
export const UPDATE_PROFILE = '@account/update-profile';
export const CREATE_USER = '@account/create-user';
export const GET_USERS = '@account/get-users';
export const GET_EDIT_USER = '@account/get-edit-user';
export const CHANGE_PASSWORD = '@account/change-password';
export const CHANGE_STATUS = '@account/change-status';
export const ADD_REDIRECT_TO_LIST = '@account/add-redirect-to-list';
export const CLEAR_REDIRECT_TO_LIST = '@account/clear-redirect-to-list';

export function login(email, password, tenantCode) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      await authService.loginWithEmailAndPassword(email, password, tenantCode);

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();
        translateService.update(i18n, user.tenantID);

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user
          }
        });
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function loginExternal(accessToken) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_EXTERNAL_REQUEST });

      await authService.loginWithExternal(accessToken);

      dispatch({
        type: LOGIN_EXTERNAL_SUCCESS,
        payload: {
          accessToken
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_EXTERNAL_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register(values) {
  return async dispatch => {
    try {
      dispatch({ type: REGISTER_REQUEST });

      await authService.registerWithEmail(values.companyName, values.email);

      dispatch({
        type: REGISTER_SUCCESS
      });
    } catch (error) {
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function confirmRegistration(email, newPassword, tenantCode, isInvitation) {
  return async dispatch => {
    try {
      dispatch({ type: CONFIRM_REGISTRATION_REQUEST });

      await authService.confirmRegistration(email, newPassword, tenantCode, isInvitation);

      dispatch({
        type: CONFIRM_REGISTRATION_SUCCESS
      });
    } catch (error) {
      dispatch({ type: CONFIRM_REGISTRATION_FAILURE });
      throw error;
    }
  };
}

const CREATE_USER_MUTATION = gql`
  mutation SaveUser($userInput: UserInput!, $modUser: String!, $confirmUrl: String) {
    saveUser(userInput: $userInput, modUser: $modUser, confirmUrl: $confirmUrl) {
      id
      firstName
      lastName
      initials
      email
      mobile
      ahvNumber
      workload
      annualGrossSalary
      eligibleForLostHours
      hasDecisionMakingPosition
      createDate
      lastLogin
      isActive
      userGroupID
    }
  }
`;

export function createUser(newUser, modUser) {
  // transform object for group
  // we leave it empy for now until the business rules are more clear
  // newUser.userGroup = { id: newUser.userGroupId };
  // newUser.userGroupID = undefined;
  const request = client.mutate({
    mutation: CREATE_USER_MUTATION,
    variables: {
      userInput: newUser,
      modUser: modUser,
      confirmUrl: process.env.REACT_APP_PUBLIC_HOST + PATH_CONFIRM_INVITATION
    }
  });
  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: CREATE_USER,
          payload: { user: response.data.saveUser }
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Created user ${response.data.saveUser.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($userUpdateInput: UserUpdateInput!) {
    updateUser(userUpdateInput: $userUpdateInput) {
      id
      firstName
      lastName
      initials
      email
      mobile
      ahvNumber
      workload
      annualGrossSalary
      eligibleForLostHours
      hasDecisionMakingPosition
      createDate
      lastLogin
      isActive
      userGroupID
    }
  }
`;

export function updateProfile(update) {
  const request = client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: { userUpdateInput: update }
  });
  return dispatch => {
    request.then(
      response => {
        dispatch({
          type: UPDATE_PROFILE,
          payload: { user: response.data.updateUser }
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Updated user ${response.data.updateUser.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($newPassword: String!, $modUser: String!) {
    changePassword(newPassword: $newPassword, modUser: $modUser) {
      id
    }
  }
`;

export function changePassword(newPassword) {
  const request = client.mutate({
    mutation: CHANGE_PASSWORD_MUTATION,
    variables: { newPassword: newPassword, modUser: 'test' }
  });

  return dispatch => {
    request.then(
      response =>
        dispatch({
          type: CHANGE_PASSWORD,
          payload: response.data
        }),
      error => handleResponseError(error, dispatch)
    );
  };
}

const GET_USERS_QUERY = gql`
  query {
    users {
      id
      firstName
      lastName
      initials
      email
      mobile
      ahvNumber
      workload
      annualGrossSalary
      eligibleForLostHours
      hasDecisionMakingPosition
      createDate
      lastLogin
      isActive
      userGroupID
      userGroup {
        name
      }
    }
  }
`;

export function getUsers() {
  const request = client.query({
    query: GET_USERS_QUERY,
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_USERS,
        payload: response.data
      })
    );
  };
}

const CHANGE_STATUS_MUTATION = gql`
  mutation ChangeStatus($newStatus: Boolean!, $userId: Int!, $modUser: String!) {
    changeStatus(newStatus: $newStatus, userId: $userId, modUser: $modUser) {
      id
      isActive
    }
  }
`;

export function changeStatus(newStatus, userId, modUser) {
  const request = client.mutate({
    mutation: CHANGE_STATUS_MUTATION,
    variables: { newStatus, userId, modUser }
  });

  return dispatch => {
    request.then(
      response =>
        dispatch({
          type: CHANGE_STATUS,
          payload: response.data.changeStatus
        }),
      error => handleResponseError(error, dispatch)
    );
  };
}

const GET_USER_QUERY = gql`
  query GetUser($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      initials
      email
      mobile
      ahvNumber
      workload
      annualGrossSalary
      eligibleForLostHours
      hasDecisionMakingPosition
      createDate
      lastLogin
      isActive
      userGroupID
    }
  }
`;

export function getEditUser(userId) {
  const request = client.query({
    query: GET_USER_QUERY,
    variables: { id: userId },
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_EDIT_USER,
        payload: { editUser: response.data.user }
      })
    );
  };
}

export function clearRedirectToList(message) {
  return dispatch => {
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: message
      }
    });
    dispatch({ type: CLEAR_REDIRECT_TO_LIST, payload: {} });
  };
}
