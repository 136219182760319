/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  GET_USERS,
  CREATE_USER,
  CHANGE_STATUS,
  GET_EDIT_USER,
  ADD_REDIRECT_TO_LIST,
  CLEAR_REDIRECT_TO_LIST
} from 'src/actions/accountActions';
import { GET_USERGROUPS } from 'src/actions/companyActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case GET_USERS: {
      const { users } = omitDeep(action.payload, '__typename');

      return produce(state, draft => {
        draft.users = users;
      });
    }

    case CHANGE_STATUS: {
      const { id, isActive } = action.payload;
      var index = state.users.findIndex(user => user.id === id);

      return produce(state, draft => {
        draft.users[index].isActive = isActive;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = omitDeep(action.payload, '__typename');

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, draft => {
        if (draft.user.id === user.id) {
          draft.user = user;
        } else {
          draft.editUser = user;
        }
        if (draft.users) {
          var index = state.users.findIndex(u => u.id === user.id);
          if (index >= 0) {
            draft.users[index] = user;
          }
        }
      });
    }

    case CREATE_USER: {
      const { user } = action.payload;
      if (typeof state.users !== 'undefined') {
        return produce(state, draft => {
          draft.users = [...state.users, user];
        });
      }
      return state;
    }

    case GET_EDIT_USER: {
      const { editUser } = action.payload;
      return produce(state, draft => {
        draft.editUser = editUser;
      });
    }

    case GET_USERGROUPS: {
      const { userGroups } = action.payload;
      return produce(state, draft => {
        draft.userGroups = userGroups;
      });
    }

    case ADD_REDIRECT_TO_LIST: {
      const { message } = action.payload;
      return produce(state, draft => {
        draft.redirectToList = true;
        draft.redirectMessage = message;
      });
    }

    case CLEAR_REDIRECT_TO_LIST: {
      return produce(state, draft => {
        draft.redirectToList = false;
        draft.redirectMessage = undefined;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
