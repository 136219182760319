/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SYSTEM,
  GET_SYSTEMS,
  GET_SEARCH_SYSTEMS,
  GET_SYSTEM_PARAMS,
  UPDATE_SYSTEM,
  GET_SYSTEMS_DETAILS,
  GET_OWNERS,
  GET_ADMINS,
  SAVE_SYSTEM,
  GET_CUSTOMERS,
  SET_SYSTEM_OWNER,
  GET_PARTS,
  GET_SUPPLIERS,
  SAVE_SYSTEM_PARAMETER
} from 'src/actions/systemActions';
import { UPLOAD_SYSTEM_PARAM } from 'src/actions/fileActions';
const initialState = {
  systems: null,
  systemParams: []
};

const contractReducer = (state = initialState, action) => {
  var omitDeep = require('omit-deep-lodash');
  switch (action.type) {
    case GET_SYSTEMS: {
      const { systems } = action.payload;
      return produce(state, draft => {
        draft.systems = systems;
      });
    }

    case GET_SEARCH_SYSTEMS:
      const { searchSystems } = action.payload;
      return produce(state, draft => {
        draft.searchSystems = searchSystems;
      });

    case GET_SYSTEM: {
      let { system } = action.payload;
      return produce(state, draft => {
        draft.system = omitDeep(system, '__typename');
      });
    }

    case GET_PARTS: {
      let { parts } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.parts = parts;
      });
    }

    case GET_SYSTEM_PARAMS: {
      const { systemParams } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.systemParams = systemParams;
        draft.reloadSystemParam = false;
      });
    }

    case GET_SUPPLIERS: {
      const { suppliers } = action.payload;
      return produce(state, draft => {
        draft.suppliers = suppliers;
      });
    }

    case UPDATE_SYSTEM: {
      const { updateSystem } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.system = updateSystem;
        if (draft.systems && draft.systems.length > 0) {
          var index = draft.systems.findIndex(s => s.id === updateSystem.id);
          if (index >= 0) {
            draft.systems[index].code = updateSystem.code;
            draft.systems[index].maintainer = updateSystem.maintainer;
            draft.systems[index].address = updateSystem.address;
            draft.systems[index].remark = updateSystem.remark;
          }
        }
      });
    }

    case SAVE_SYSTEM: {
      const { saveSystem } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.system = saveSystem;
        if (draft.systems && draft.systems.length > 0) {
          var index = draft.systems.findIndex(s => s.id === saveSystem.id);
          if (index >= 0) {
            draft.systems[index].code = saveSystem.code;
            draft.systems[index].maintainer = saveSystem.maintainer;
            draft.systems[index].address = saveSystem.address;
            draft.systems[index].remark = saveSystem.remark;
          } else {
            draft.systems = [...draft.systems, saveSystem];
          }
        }
      });
    }

    case GET_SYSTEMS_DETAILS: {
      const { systemDetails } = action.payload;
      return produce(state, draft => {
        draft.systemDetails = systemDetails;
      });
    }

    case SET_SYSTEM_OWNER: {
      const { owner } = action.payload;
      return produce(state, draft => {
        draft.system.owner = owner;
      });
    }

    case GET_OWNERS: {
      const { owners } = action.payload;
      return produce(state, draft => {
        draft.owners = owners;
      });
    }

    case GET_ADMINS: {
      const { admins } = action.payload;
      return produce(state, draft => {
        draft.admins = admins;
      });
    }

    case GET_CUSTOMERS: {
      const { customers } = action.payload;
      return produce(state, draft => {
        draft.customers = customers;
      });
    }

    case UPLOAD_SYSTEM_PARAM: {
      return produce(state, draft => {
        draft.reloadSystemParam = true;
      });
    }
    case SAVE_SYSTEM_PARAMETER: {
      const { saveSystemParameter } = omitDeep(action.payload, '__typename');
      return produce(state, draft => {
        draft.systemParams = draft.systemParams.map(sp =>
          sp.id === saveSystemParameter.id ? saveSystemParameter : sp
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default contractReducer;
