import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'src/store/storeInterface';
import client from 'src/utils/apolloClient';
import { ReportDocument, ReportQueryVariables, ReportsDocument } from 'src/generated/graphql';

export const GET_REPORT = '@report/get-report';
export const GET_REPORTS = '@report/get-reports';

export function getReports(): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: ReportsDocument,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_REPORTS,
        payload: { reports: response.data.reports }
      });
    });
  };
}

export function getReport(
  reportQueryVariables: ReportQueryVariables
): ThunkAction<void, RootState, unknown, Action<string>> {
  const request = client.query({
    query: ReportDocument,
    variables: reportQueryVariables,
    fetchPolicy: 'no-cache'
  });
  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_REPORT,
        payload: { report: response.data.report }
      });
    });
  };
}
