import { gql } from 'apollo-boost';
import client from 'src/utils/apolloClient';
import { ADD_QUE_MESSAGE, handleResponseError } from '../actions/errorActions';

export const GET_CONTRACTS = '@contract/get-contracts';
export const GET_CONTRACT = '@contract/get-contract';
export const CHANGE_CONTRACT_STATUS = '@contract/change-contract-status';
export const SAVE_CONTRACT = '@contract/save-contract';
export const COPY_CONTRACT = '@contract/copy-contract';
export const CREATE_CONTRACT = '@contract/create-contract';
export const NEW_CONTRACT_HANDLED = '@contract/new-contract-handled';
export const CLEAR_REDIRECT_TO_LIST = '@contract/clear-redirect-to-list';
export const ADD_REDIRECT_TO_LIST = '@contract/add-redirect-to-list';

const GET_CONTRACTS_QUERY = gql`
  query {
    maintenanceContracts {
      id
      createdDate
      endDate
      status
      internalRemark
      system {
        id
        code
        address {
          id
          street
        }
        owner {
          id
          name
        }
        customer {
          id
          name
        }
        administration {
          id
          name
        }
      }
    }
  }
`;

export function getContracts() {
  const request = client.query({
    query: GET_CONTRACTS_QUERY,
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CONTRACTS,
        payload: response.data
      })
    );
  };
}

const GET_CONTRACT_QUERY = gql`
  query MaintenanceContract($id: Int!) {
    maintenanceContract(id: $id) {
      id
      systemID
      status
      system {
        id
        code
        address {
          id
          street
          zip
          city
        }
      }
      creatorID
      signedDate
      startDate
      asmIndex
      asmStatus
      asmBasis
      totalPrice
      currencyCode
      offerFactor
      fullMaintenanceFactor
      internalRemark
      paymentConditions
      amountOfInspections
      details {
        systemParamID
        systemParam {
          id
          name
          price
          basicCharge
          systemParamCategory {
            id
            name
          }
        }
        contractPrice
        value
        currencyCode
      }
    }
  }
`;

export function getContract(contractId) {
  console.log('get contract');
  const request = client.query({
    query: GET_CONTRACT_QUERY,
    variables: { id: contractId },
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CONTRACT,
        payload: response.data
      })
    );
  };
}

const CHANGE_CONTRACT_STATUS_MUTATION = gql`
  mutation ChangeMaintenanceContract(
    $id: Int!
    $status: MaintenanceContractStatus!
    $remark: String
    $date: DateTime!
    $modUser: String!
  ) {
    changeMaintenanceContract(id: $id, status: $status, remark: $remark, date: $date, modUser: $modUser) {
      id
      systemID
      status
      system {
        id
        code
        address {
          id
          street
          zip
          city
        }
      }
      creatorID
      startDate
      asmIndex
      asmStatus
      asmBasis
      totalPrice
      currencyCode
      offerFactor
      fullMaintenanceFactor
      internalRemark
      paymentConditions
      amountOfInspections
      details {
        systemParamID
        systemParam {
          id
          name
          basicCharge
          systemParamCategory {
            id
            name
          }
        }
        contractPrice
        value
        currencyCode
      }
    }
  }
`;

export function changeContractStatus(contractId, status, remark, date, modUser) {
  const request = client.mutate({
    mutation: CHANGE_CONTRACT_STATUS_MUTATION,
    variables: { id: contractId, status, remark, date, modUser }
  });

  return async dispatch => {
    await request.then(
      response => {
        dispatch({
          type: CHANGE_CONTRACT_STATUS,
          payload: response.data
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Updated contract ${response.data.changeMaintenanceContract.id}`,
              options: { variant: 'success' }
            }
          }
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Updated contract ${response.data.changeMaintenanceContract.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

const SAVE_CONTRACT_MUTATION = gql`
  mutation SaveMaintenanceContract($maintenanceContractInput: MaintenanceContractInput!, $modUser: String!) {
    saveMaintenanceContract(maintenanceContractInput: $maintenanceContractInput, modUser: $modUser) {
      id
      systemID
      status
      system {
        id
        code
        address {
          id
          street
          zip
          city
        }
      }
      creatorID
      startDate
      asmIndex
      asmStatus
      asmBasis
      totalPrice
      currencyCode
      offerFactor
      fullMaintenanceFactor
      internalRemark
      paymentConditions
      amountOfInspections
      details {
        systemParamID
        systemParam {
          id
          name
          basicCharge
          systemParamCategoryID
          systemParamCategory {
            id
            name
          }
        }
        contractPrice
        value
        currencyCode
      }
    }
  }
`;

export function saveContract(contract, modUser) {
  let inputContract = { ...contract };

  const request = client.mutate({
    mutation: SAVE_CONTRACT_MUTATION,
    variables: { maintenanceContractInput: inputContract, modUser: modUser }
  });

  return async dispatch => {
    await request.then(
      response => {
        if (response && response.data.saveMaintenanceContract) {
          dispatch({
            type: SAVE_CONTRACT,
            payload: response.data
          });
          dispatch({
            type: ADD_REDIRECT_TO_LIST,
            payload: {
              message: {
                text: `Saved contract ${response.data.saveMaintenanceContract.id}`,
                options: { variant: 'success' }
              }
            }
          });
        }
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

const COPY_CONTRACT_MUTATION = gql`
  mutation CopyMaintenanceContract($id: Int!, $modUser: String!) {
    copyMaintenanceContract(id: $id, modUser: $modUser) {
      id
      createdDate
      endDate
      status
      internalRemark
      system {
        id
        code
        address {
          id
          street
        }
        owner {
          id
          name
        }
        customer {
          id
          name
        }
        administration {
          id
          name
        }
      }
    }
  }
`;

export function clearRedirectToList(message) {
  return dispatch => {
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: message
      }
    });
    dispatch({ type: CLEAR_REDIRECT_TO_LIST, payload: {} });
  };
}

export function newContractHandled() {
  return { type: NEW_CONTRACT_HANDLED, payload: {} };
}

export function copyContract(contractId, modUser) {
  const request = client.mutate({
    mutation: COPY_CONTRACT_MUTATION,
    variables: { id: contractId, modUser: modUser }
  });

  return async dispatch => {
    await request.then(
      response => {
        dispatch({
          type: COPY_CONTRACT,
          payload: response.data
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Copied contract ${contractId}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function createNewContract(userId) {
  const contract = { id: 0, status: 'PENDING', creatorID: userId };
  console.log('Create contract action');
  console.log(contract);
  return { type: CREATE_CONTRACT, payload: { contract } };
}
