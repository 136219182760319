import client from 'src/utils/apolloClient';
import { ADD_QUE_MESSAGE, handleResponseError } from '../actions/errorActions';
import {
  CHANGE_OFFER_STATUS_MUTATION,
  SAVE_OFFER_MUTATION,
  GET_OFFERS_QUERY,
  GET_OFFER_QUERY,
  GET_SERVICES_QUERY
} from './GQL/offerActionsGQL';

export const GET_OFFERS = '@offer/get-offers';
export const GET_OFFER = '@offer/get-offer';
export const SAVE_OFFER = '@offer/save-offer';
export const CHANGE_OFFER_STATUS = '@offer/change-offer-status';
export const GET_SERVICES = '@offer/get-services';
export const ADD_REDIRECT_TO_LIST = '@offer/add-redirect-to-list';
export const CLEAR_REDIRECT_TO_LIST = '@offer/clear-redirect-to-list';

export function getOffer(offerId) {
  const request = client.query({
    query: GET_OFFER_QUERY,
    variables: { id: offerId },
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    if (offerId === 0) {
      dispatch({ type: GET_OFFER, payload: { offer: { id: 0, status: 'PENDING', createdDate: new Date() } } });
    } else {
      request.then(response =>
        dispatch({
          type: GET_OFFER,
          payload: response.data
        })
      );
    }
  };
}

export function changeOfferStatus(offerID, status, date, modUser) {
  const request = client.mutate({
    mutation: CHANGE_OFFER_STATUS_MUTATION,
    variables: { id: offerID, status: status, date: date, modUser: modUser }
  });

  return async dispatch => {
    await request.then(
      response => {
        dispatch({
          type: CHANGE_OFFER_STATUS,
          payload: response.data
        });
        dispatch({
          type: ADD_QUE_MESSAGE,
          payload: {
            message: {
              text: `Updated offer ${offerID}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function saveOffer(offer, modUser) {
  const request = client.mutate({
    mutation: SAVE_OFFER_MUTATION,
    variables: { offerInput: offer, modUser: modUser }
  });

  return async dispatch => {
    await request.then(
      response => {
        dispatch({
          type: SAVE_OFFER,
          payload: response.data
        });
        dispatch({
          type: ADD_REDIRECT_TO_LIST,
          payload: {
            message: {
              text: `Saved offer ${response.data.saveOffer.id}`,
              options: { variant: 'success' }
            }
          }
        });
      },
      error => handleResponseError(error, dispatch)
    );
  };
}

export function getOffers() {
  const request = client.query({
    query: GET_OFFERS_QUERY,
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_OFFERS,
        payload: response.data
      })
    );
  };
}

export function getServices() {
  const request = client.query({
    query: GET_SERVICES_QUERY,
    fetchPolicy: 'no-cache'
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SERVICES,
        payload: response.data
      })
    );
  };
}

export function clearRedirectToList(message) {
  return dispatch => {
    dispatch({
      type: ADD_QUE_MESSAGE,
      payload: {
        message: message
      }
    });
    dispatch({ type: CLEAR_REDIRECT_TO_LIST, payload: {} });
  };
}
